.signUp-container {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    margin-bottom: 2%;
    background-image: url('../../public/images/robo-rally-text.png');
    background-size: contain;
    /* Resmi kapsayacak şekilde ayarlar */
    background-position: center;
    /* Resmi ortalar */
    background-repeat: no-repeat;
    /* Resmin tekrarını engeller */
    width: 100vw;
    min-height: 140vh;

}

.my-container-signup {
    width: 80%;
    background-color: "rgba(0, 0, 0, 0.01)";
    border-radius: 50px;
    display: flex;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.5);
    justify-content: center;
    flex-direction: column;
    align-items: center;
    flex-wrap: wrap;
    padding: 1%;
    margin-top: 7vh;

}


.left-text-div {
    width: 80%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding-top: 2rem;

}

.txt-kayit {
    color: black;
    font-size: 1.8vw;
    font-weight: bold;
    font-family: "PoppinsMedium"
}

.txt-kayit-dec {
    color: rgb(107, 105, 105);
    font-size: 1vw;
    font-family: "PoppinsLight";
    text-align: left;
}

.txt-kayit-dec-uyari {
    color: rgb(34, 32, 32);
    font-size: 1vw;
    font-family: "PoppinsBold";
    text-align: left;

}



.right-container-signup {
    width: 80%;
    display: flex;
    justify-content: center;
}

.custom-input {
    border: none;
    border-bottom: 2px solid #000;
    border-radius: 0;
    background-color: transparent !important;
    font-size: 1vw;
    font-family: "PoppinsRegular";
}

.custom-input option {
    font-family: "PoppinsRegular";
    font-size: 1vw;

}

.signUp-btn {
    background-color: #8f9396;
    color: #ffffff;
    border-radius: 15px;
    padding-top: 0.9rem;
    padding-bottom: 0.9rem;
    margin-top: 2rem;
    margin-bottom: 1rem;
    width: 30vw;
    margin-right: 20%;
    font-Family: "PoppinsMedium";
    font-size: 1.04vw;
    border: none;
    cursor: pointer;
}

/* Disabled durumu için özel stil */
.signUp-btn:disabled {
    background-color: #d3d3d3;
    /* Daha soluk bir renk */
    color: #a0a0a0;
    /* Metin rengini de soluk yap */
    cursor: not-allowed;
    /* Tıklanamaz işareti */
    opacity: 0.6;
    /* Biraz daha opak yap */
}

.form-label {
    display: block;
    text-align: start;
    font-family: "PoppinsRegular";
    font-size: 1vw;
}


.form-check-box-txt1 {
    text-decoration: underline;
    cursor: pointer;
    color: #000;
    font-family: "PoppinsBold";
    font-size: 0.83vw;
}

.form-check-box-txt2 {
    font-family: "PoppinsRegular";
    font-size: 0.83vw;
}


@media (min-width: 768px) and (max-width: 1024px) {

    .signUp-container {
        margin-bottom: 1%;
        min-height: 130vh;

    }

    .my-container-signup {
        width: 95%;
        border-radius: 30px;
        margin-top: 6vh;
    }

    .txt-kayit {
        font-size: 3.5vw;
    }

    .txt-kayit-dec-uyari {
        font-size: 2.5vw;
    }

    .txt-kayit-dec {
        font-size: 2.5vw;
    }

    .right-container-signup {
        width: 95%;
    }

    .custom-input {
        font-size: 2.5vw;
        width: 75vw;
    }

    .signUp-btn {
        width: 75vw;
        font-size: 2.5vw;
    }

    .form-label {
        font-size: 2.5vw;
    }

    .form-check-box-txt1 {
        font-size: 2vw;
    }

    .form-check-box-txt2 {
        font-size: 2vw;
    }

}


/* Ekran genişliği 768px veya daha küçük olduğunda */
@media (max-width: 768px) {

    .signUp-container {
        min-height: 160vh;

    }

    .my-container-signup {
        width: 95%;
        border-radius: 20px;
        margin-top: 5vh;

    }

    .txt-kayit {
        font-size: 5vw;
    }

    .txt-kayit-dec-uyari {
        font-size: 3vw;
    }
    .txt-kayit-dec {
        font-size: 3vw;
    }

    .right-container-signup {
        width: 100%;
    }

    .custom-input {
        border-bottom: 2px solid #000;
        border-radius: 0;
        background-color: transparent !important;
        font-size: 4vw;
        width: 80vw;
    }


    .signUp-btn {
        width: 80vw;
        font-size: 3vw;
    }

    .form-label {
        font-size: 3vw;
    }

    .form-check-box-txt1 {
        font-size: 2.3vw;
    }

    .form-check-box-txt2 {

        font-family: "PoppinsRegular";
        font-size: 2.3vw;
    }


}
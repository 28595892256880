.archive-2023 {
    display: flex;
    gap: 8vh;
    flex-direction: column;
    align-items: center;
    background-image: url('../../public/images/archive/archivebackground.png');
    background-size: cover;
    /* Resmi kapsayacak şekilde ayarlar */
    background-repeat: no-repeat;
    /* Resmin tekrarını engeller */
    width: 100vw;
    min-height: 190vh;
}

.roboRally-2023 {
    border: 1px solid black;
    border-radius: 40px;
    background-color: #fff;
    display: inline-block;
    overflow: hidden;
    padding: 0 2vw;
    margin-top: 3vh;

}

.roboRally-text-2023 {
    margin: 0.5rem;
    font-size: 1vw;
    font-family: "PoppinsLight";
}

.menu-btn-div-2023 {
    width: 100vw;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    gap: 4vw;

}

.menu-btn-2023 {
    background-color: #fff;
    color: #000;
    border-radius: 40px;
    padding: 1.2vh 3vw;
    font-size: 1vw;
    font-family: "PoppinsRegular";
    filter: blur(1.5px);
    transition: filter 0.3s ease-in-out;

}

.menu-btn-2023:hover,
.menu-btn-2023:active,
.menu-btn-2023:focus {
    filter: blur(0);
    /* Üzerine gelindiğinde veya tıklandığında netleşir */
}

.menu-btn-2023.active {
    filter: blur(0);
    /* Aktif buton net olur */
}

.my-container-archive-2023 {
    width: 80%;
    background-color: rgba(255, 255, 255, 0.5);
    /* Saydam arka plan */
    border-radius: 80px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* İçeriği ortalamak için */
    flex-wrap: wrap;
    box-shadow: inset 0px 2px 5px rgba(100, 100, 100, 0.9);
    /* Beyaz iç kenar gölgeliği */
}

.archive-2023-goruntu-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 4vw;
    margin-top: 5vh;
    cursor: pointer;
}

.youtube-img-2023 {
    width: 32vw;
    height: auto;
    object-fit: contain;
    position: relative;
}

.archive-2023-goruntu-container p {
    font-family: "PoppinsSemiBold";
    font-size: 1vw;
    line-height: 2rem;
    margin-left: 2rem;
    text-align: start;

}

.archive-2023-goruntu-alt-container {
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    background-color: rgba(255, 255, 255, 0.5);
    box-shadow: inset 0px 2px 5px rgba(100, 100, 100, 0.9);
}

.first-goruntu-div,
.second-goruntu-div {
    display: flex;
    flex-direction: row;
    gap: 2vw;
    margin-bottom: 1vh;
    margin-top: 4vh;
}

.first-goruntu-div img,
.second-goruntu-div img {
    width: 35vw;
    height: auto;
    transition: transform 0.3s ease;
    /* Yumuşak geçiş efekti */
}

.first-goruntu-div img:hover,
.second-goruntu-div img:hover {
    transform: scale(1.4);
    /* Resmi %10 oranında büyüt */
}

.derecekazananlar2023 {
    object-fit: cover;
    width: 80%;
    height: auto;
    margin-bottom: 2rem;

}

.yarisanlar2023 {
    object-fit: cover;
    margin: 1%;
    width: 95%;
    height: auto;
}

@media (min-width: 768px) and (max-width: 1024px) {

    .archive-2023 {  
        min-height: 90vh;
    }

    .archive-2023 {
        gap: 6vh;
        background-size: contain;
        width: 100vw;
    }

    .roboRally-2023 {
        padding: 0 2.5vw;
        margin-top: 2.5vh;

    }

    .roboRally-text-2023 {
        font-size: 3vw;
    }

    .menu-btn-div-2023 {
        gap: 3vw;
    }

    .menu-btn-2023 {
        width: 25rem;
        font-size: 3vw;
        filter: blur(1.2px);
    }

    .my-container-archive-2023 {
        width: 95%;
        border-radius: 35px;
        flex-direction: column;
    }

    .archive-2023-goruntu-container {
        justify-content: center;
        align-items: center;
        margin: 0.8vh;
    }

    .youtube-img-2023 {
        width: 87vw;
        margin-top: 4%;
    }

    .archive-2023-goruntu-container p {
        font-size: 2.7vw;
        line-height: 1.4;
        margin-left: 1rem;
    }

    .archive-2023-goruntu-alt-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        max-height: 50vh;
        overflow-y: auto;
        margin-bottom: 5%;
    }

    .first-goruntu-div,
    .second-goruntu-div {
        display: flex;
        flex-direction: row;
        gap: 1.2vw;
        margin-bottom: 0.5vh;
        margin-top: 0.5vh;
    }

    .first-goruntu-div img,
    .second-goruntu-div img {
        width: 95vw;
        height: auto;

    }

    .first-goruntu-div img:hover,
    .second-goruntu-div img:hover {
        transform: scale(1.0);
        /* Resmi %10 oranında büyüt */
    }

    .archive-2023-goruntu-alt-container::-webkit-scrollbar {
        height: 10px;
        /* Yatay scrollbar için daha dar */
        width: 10px;
        /* Dikey scrollbar için daha dar */
    }

    .archive-2023-goruntu-alt-container::-webkit-scrollbar-thumb {
        border-radius: 5px;
        /* Mobilde daha küçük tutamaç */
        background: linear-gradient(to top, darkblue, #1bb2ef);
        /* Tutamaç rengi */
    }

    .archive-2023-goruntu-alt-container::-webkit-scrollbar-thumb:hover {
        background: #127bf3;
    }

    .derecekazananlar2023 {
        width: 100%;
        height: auto;
        margin-bottom: 1rem;

    }

    .yarisanlar2023 {
        width: 95%;
    }
}

/* Ekran genişliği 768 pikselden küçükse */
@media (max-width: 768px) {

    .archive-2023 {
        gap: 5vh;
        background-size: contain;
        width: 100vw;
        min-height: 120vh;
    }

    .roboRally-2023 {
        padding: 0 2vw;
        margin-top: 2vh;

    }

    .roboRally-text-2023 {
        font-size: 3vw;
    }

    .menu-btn-div-2023 {
        gap: 3vw;
    }

    .menu-btn-2023 {
        width: 12rem;
        font-size: 3vw;
        filter: blur(1.2px);
    }

    .my-container-archive-2023 {
        width: 95%;
        border-radius: 30px;
        flex-direction: column;
    }

    .archive-2023-goruntu-container {
        justify-content: center;
        align-items: center;
        margin: 0.8vh;
    }

    .youtube-img-2023 {
        width: 85vw;
        margin-top: 4%;
    }

    .archive-2023-goruntu-container p {
        font-size: 3vw;
        line-height: 1rem;
        margin-left: 1rem;
    }

    .archive-2023-goruntu-alt-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        max-height: 50vh;
        overflow-y: auto;
        margin-bottom: 5%;

    }

    .first-goruntu-div,
    .second-goruntu-div {
        display: flex;
        flex-direction: row;
        gap: 1.2vw;
        margin-bottom: 0.5vh;
        margin-top: 0.5vh;
    }

    .first-goruntu-div img,
    .second-goruntu-div img {
        width: 95vw;
        height: auto;

    }

    .first-goruntu-div img:hover,
    .second-goruntu-div img:hover {
        transform: scale(1.0);
        /* Resmi %10 oranında büyüt */
    }

    .archive-2023-goruntu-alt-container::-webkit-scrollbar {
        height: 10px;
        /* Yatay scrollbar için daha dar */
        width: 10px;
        /* Dikey scrollbar için daha dar */
    }

    .archive-2023-goruntu-alt-container::-webkit-scrollbar-thumb {
        border-radius: 5px;
        /* Mobilde daha küçük tutamaç */
        background: linear-gradient(to top, darkblue, #1bb2ef);
        /* Tutamaç rengi */
    }

    .archive-2023-goruntu-alt-container::-webkit-scrollbar-thumb:hover {
        background: #127bf3;
    }

    .derecekazananlar2023 {
        width: 100%;
        height: auto;
        margin-bottom: 1rem;

    }

    .yarisanlar2023 {
        width: 90%;
    }
}
.ask-us-bg-img {
    display: flex;
    justify-content: center;
    flex-direction: row;
    background-image: url('../../public/images/robo-rally-text.png');
    background-size: contain;
    background-position: top;
    background-repeat: no-repeat;
    width: 100vw;
}

.ask-us-menu-container {
    flex: 0.3; 
    height: max-content; 
    justify-content: center;
    display: flex;
}

.ask-us-menu {  
  margin-top: 6rem; 
  margin-left: 1rem;
  width: 80%; 
  padding: 2rem;
  border-radius: 35px;
  box-shadow: 0px -3px 3px 2px rgba(213, 213, 213, 5);
  background-color: rgba(255, 255, 255, 0.3);
}

.ask-us-menu-text {
    color: black;
    font-size: 1.25rem;
    font-family: 'PoppinsLight';
    display: flex;
}

.ask-question-icon {
    background-image: url('../../public/images/ask-us/ask-question.png'); 
    background-size: contain; 
    background-repeat: no-repeat; 
    background-position: center;
    width: 18px; 
    height: 18px; 
    display: inline-block; 
    margin-top: 0.9rem;  
}

.your-question-icon {
    background-image: url('../../public/images/ask-us/your-question.png'); 
    background-size: contain; 
    background-repeat: no-repeat; 
    background-position: center;
    width: 18px; 
    height: 18px; 
    display: inline-block; 
    margin-top: 0.9rem;   
}

.frequently-ask-question-icon {
    background-image: url('../../public/images/ask-us/frequently-ask-question.png');
    background-size: contain; 
    background-repeat: no-repeat; 
    background-position: center;
    width: 18px; 
    height: 18px; 
    display: inline-block; 
    margin-top: 0.9rem;    
}

.nav-item {
    display: flex;
}

.nav-link.active {
    width: 100%;
    background: linear-gradient(to right, darkblue, #1bb2ef) !important;
    box-shadow: 0px -2px 1px 2px rgba(0, 63, 135, 5);
    color: white !important;
    border-radius: 25px;
}

.nav-link:hover {
    color: rgb(131, 131, 131) !important;
}

.ask-us-page {
    flex: 0.7;
    display: flex;
    align-items: top; 
    justify-content: flex-start;


}

.ask-us-page-card {
    width: 60vw;
    height: auto;
    justify-content: center;
    margin: 1rem 0;
     background-color: rgb(255, 255, 255); 
    border-radius: 35px;
    margin-bottom: 5rem;
    margin-top: 7rem;
    box-shadow: 0px -3px 3px 2px rgba(213, 213, 213, 5);
}

@media (min-width: 768px) and (max-width: 1024px) {

    .ask-us-bg-img {
        display: flex;
        flex-direction: column;  
    }  
    
    .ask-us-menu {  
      margin-top: 2rem; 
      margin-left: 0rem;
      width: 60%; 
      border-radius: 20px;
      box-shadow: 0px -3px 3px 2px rgba(213, 213, 213, 5);
      background-color: rgba(255, 255, 255, 0.3);
    }
    
    .ask-us-menu-text {
        color: black;
        font-size: 1rem;
        font-family: 'PoppinsLight';
        display: flex;
    }   
    
    .nav-item {
        display: flex;
    }
    
    .nav-link.active {
        width: 100%;
        background: linear-gradient(to right, darkblue, #1bb2ef) !important;
        box-shadow: 0px -2px 1px 2px rgba(0, 63, 135, 5);
        color: white !important;
        border-radius: 25px;
    }
    
    .nav-link:hover {
        color: rgb(131, 131, 131) !important;
    }

    .ask-us-page {
        display: flex;
        align-items: top; 
        justify-content: center;   
    
    }
    
    .ask-us-page-card {
        width: 95vw;
        margin: 1rem 0;
        border-radius: 25px;
        margin-bottom: 5rem;
        margin-top: 2rem;
    }

}

/* Ekran genişliği 768 pikselden küçükse */
@media (max-width: 768px) {

    .ask-us-bg-img {
        display: flex;
        flex-direction: column;  
    }  
    
    .ask-us-menu {  
      margin-top: 2rem; 
      margin-left: 0rem;
      width: 90%; 
      border-radius: 25px;
      box-shadow: 0px -3px 3px 2px rgba(213, 213, 213, 5);
      background-color: rgba(255, 255, 255, 0.3);
    }
    
    .ask-us-menu-text {
        color: black;
        font-size: 0.9rem;
        font-family: 'PoppinsLight';
        display: flex;
    }
    

    
    .nav-item {
        display: flex;
    }
    
    .nav-link.active {
        width: 100%;
        background: linear-gradient(to right, darkblue, #1bb2ef) !important;
        box-shadow: 0px -2px 1px 2px rgba(0, 63, 135, 5);
        color: white !important;
        border-radius: 25px;
    }
    
    .nav-link:hover {
        color: rgb(131, 131, 131) !important;
    }

    .ask-us-page {
        display: flex;
        align-items: top; 
        justify-content: center;   
    
    }
    
    .ask-us-page-card {
        width: 95vw;
        margin: 1rem 0;
        border-radius: 25px;
        margin-bottom: 5rem;
        margin-top: 2rem;
    }

}



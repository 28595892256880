.manage-file-container {
    display: flex;
    gap: 6vh;
    flex-direction: column;
    align-items: center;
    margin-bottom: 2%;
    background-image: url('../../public/images/robo-rally-text.png');
    background-size: contain;
    /* Resmi kapsayacak şekilde ayarlar */
    background-position: center;
    /* Resmi ortalar */
    background-repeat: no-repeat;
    /* Resmin tekrarını engeller */
    width: 100vw;
    min-height: 120vh;
}

.manage-file-first {
    border: 0.1rem solid black;
    border-radius: 4rem;
    background-color: #fff;
    display: inline-block;
    overflow: hidden;
    margin-top: 3vh;

}

.manage-file-first-text {
    margin: 0.5rem;
    font-size: 1vw;
    font-family: "PoppinsLight";
    padding: 0vw 1.2vw;
}

.file-explorer-container {
    width: calc(100vw * 0.8);
    box-shadow: inset 0px 2px 5px rgba(100, 100, 100, 0.9);
    background-color: rgba(255, 255, 255, 0.3);
    font-family: "PoppinsSemiBold";
    border-radius: 20px;
    padding: 3%;
    max-height: 90vh;
    overflow-y: auto;
}


/* Klasör listesi */
.folder-list {
    list-style: none;
    padding: 0;
    margin: 0;
}

/* Klasör başlığı */
.folder-item {
    background-color: #ffffff96;
    margin-bottom: 10px;
    padding: 15px;
    border-radius: 9px;
    border: 1px solid #ddd;
    transition: all 0.3s ease;
}

.folder-item:hover {
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.4);
}

/* Klasör ismi ve kullanıcı bilgileri */
.folder-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    padding: 10px;
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 5px;
}

.folder-header:hover {
    background-color: #eaeaea;
}

.folder-name {
    font-size: 1vw;
    color: #2c3e50;
}

.user-info {
    font-size: 0.8vw;
    color: #616a6b;
}

/* Dosya listesi */
.file-list {
    list-style-type: none;
    padding: 0;
    margin-top: 10px;
    margin-left: 20px;
    margin-right: 20px;
}

.file-item {
    font-size: 0.8vw;
    padding: 5px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.file-actions {
    display: flex;
    gap: 1vw;
    font-size: 1.1vw;
    cursor: pointer;
}


.download-icon::after,
.delete-icon::after {
    content: attr(title);
    visibility: hidden;
    opacity: 0;
    position: absolute;
    background-color: #333;
    color: #fff;
    text-align: center;
    padding: 10px;
    border-radius: 8px;
    white-space: nowrap;
    transition: opacity 0.2s;
    margin-left: 10px;
}

.download-icon:hover::after,
.delete-icon:hover::after {
    visibility: visible;
    opacity: 1;
}

.empty {
    font-style: italic;
    color: #3b4247;
}

@media (min-width: 768px) and (max-width: 1024px) {
    .manage-file-container {
        gap: 3vh;
        background-position: top;
        min-height: 120vh;
    }


    .manage-file-first-text {
        font-size: 3vw;
    }

    .file-explorer-container {
        width: calc(100vw * 0.95);
        border-radius: 20px;
        padding: 3%;
        max-height: 90vh;
    }

    /* Klasör listesi */
    .folder-list {
        list-style: none;
        padding: 0;
        margin: 0;
    }

    /* Klasör başlığı */
    .folder-item {
        background-color: #ffffff96;
        margin-bottom: 10px;
        padding: 15px;
        border-radius: 9px;
        border: 1px solid #ddd;
        transition: all 0.3s ease;
    }

    .folder-item:hover {
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.4);
    }


    .folder-name {
        font-size: 2vw;
    }

    .user-info {
        font-size: 1.5vw;
    }

    /* Dosya listesi */
    .file-list {
        margin-left: 10px;
        margin-right: 10px;
    }

    .file-item {
        font-size: 1.5vw;
    }

    .file-actions {
        gap: 1.1vw;
        font-size: 2.1vw;
    }
}

/* Ekran genişliği 768px veya daha küçük olduğunda */
@media (max-width: 768px) {

    .manage-file-container {
        gap: 3vh;
        background-position: top;
        min-height: 80vh;
    }


    .manage-file-first-text {
        font-size: 3vw;
    }

    .file-explorer-container {
        width: calc(100vw * 0.95);
        border-radius: 20px;
        padding: 2%;
        max-height: 70vh;
    }

    /* Klasör listesi */
    .folder-list {
        list-style: none;
        padding: 0;
        margin: 0;
    }

    /* Klasör başlığı */
    .folder-item {
        background-color: #ffffff96;
        margin-bottom: 10px;
        padding: 12px;
        border-radius: 9px;
        border: 1px solid #ddd;
        transition: all 0.3s ease;
    }

    .folder-item:hover {
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.4);
    }


    .folder-name {
        font-size: 2.5vw;
    }

    .user-info {
        font-size: 1.8vw;
    }

    /* Dosya listesi */
    .file-list {
        margin-left: 10px;
        margin-right: 10px;
    }

    .file-item {
        font-size: 2vw;
    }

    .file-actions {
        gap: 1.1vw;
        font-size: 3.1vw;
    }
}
.archive-home-container {
    display: flex;
    gap: 8vh;
    flex-direction: column;
    justify-content: top;
    align-items: center;
    background-image: url('../../public/images/archive/archivebackground.png');
    background-size: cover;
    /* Resmi kapsayacak şekilde ayarlar */
    background-repeat: no-repeat;
    /* Resmin tekrarını engeller */
    width: 100vw;
    min-height: 180vh;
}

.roboRally2024 {
    border: 1px solid black;
    border-radius: 40px;
    background-color: #fff;
    display: inline-block;
    overflow: hidden;
    padding-left: 1rem;
    padding-right: 1rem;
    height: 40px;
    margin-bottom: 2vh;
}

.roboRally2024-text {
    margin: 0.5rem;
    font-size: 1vw;
    font-family: "PoppinsLight";
}

.RoborallyBirincisi-text {
    color: white;
    font-size: 2.5vw;
    position: relative;
    /* Yazıyı resmin üstünde tutar */
    z-index: 0;
    /* Yazının resmin altında görünmesini sağlar */
    margin-top: 20px;
    /* Yazıyı biraz aşağıya kaydırır */
    margin-bottom: 0px;
    font-family: "LufgaMedium";
}

.roborally2024-winner-div {
    width: 78%;
    background-color: rgba(0, 0, 0, 0.7);
    border-radius: 80px;
    display: flex;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.5);
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 1%;
    padding-bottom: 3%;
    margin-bottom: 5vh;

}

.IgdirBilimSanat-text {
    font-size: 2.5vw;
    background: linear-gradient(to right, yellow, darkorange);
    /* Gradyan tanımı */
    -webkit-background-clip: text;
    /* Gradyanı sadece metne uygular */
    -webkit-text-fill-color: transparent;
    /* Metni saydam yaparak gradyanın görünmesini sağlar */
    position: relative;
    /* Yazıyı diğer içeriklerle uyumlu hale getirir */
    z-index: 0;
    font-family: "LufgaMedium";
}

.UzunYazi-text {
    color: white;
    font-size: 1.04vw;
    position: relative;
    /* Yazıyı resmin altına yerleştirmek için */
    z-index: 0;
    margin-top: 2.5em;
    /* Yazıyı biraz aşağıya kaydırır */
    font-family: "LufgaRegular";
    text-indent: 2em;
    line-height: 1.7;

}

.vector-container {
    position: relative;
    text-align: center;
}

.vector1-image {
    position: absolute;
    top: 0%;
    left: 70%;
    transform: translateX(-50%);
    width: 2vw;
    /* Resmin genişliği */
    height: auto;
    /* Yüksekliği orantılı olarak ayarlar */
    z-index: 1;
    /* Resmin üstte görünmesini sağlar */
}

.vector-image {
    position: absolute;
    top: 9.2vw;
    /* Paragrafa göre resmi konumlandırmak için ayar yapın */
    right: 5em;
    /* Sağda konumlandırmak için */
    width: 1.5vw;
    /* Resmin genişliği */
    height: auto;
    /* Yüksekliği orantılı olarak ayarlar */
    z-index: 1;
    /* Resmin üstte görünmesini sağlar */
}

.vector2-image {
    position: absolute;
    left: 5em;
    /* Sol tarafa yerleştirmek için */
    transform: translateY(-50%);
    /* Resmi ortalamak için */
    width: 1.5vw;
    /* Resmin genişliği */
    height: auto;
    /* Yüksekliği orantılı olarak ayarlar */
    z-index: 1;
    /* Resmin üstte görünmesini sağlar */
}

.menu-buton {
    background-color: #fff;
    color: #000;
    border-radius: 40px;
    padding-top: 0.7rem;
    padding-bottom: 0.7rem;
    width: 17rem;
    font-size: 1vw;
    font-family: "PoppinsRegular";
    transition: filter 0.3s ease-in-out;
    margin-bottom: 1vh;

}

.menu-buton-div {
    width: 100vw;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;

}

.news-istanbul-div {
    width: 70%;
    display: flex;
    justify-content: top;
    flex-direction: column;
    align-items: center;
    flex-wrap: wrap;
    padding: 1%;
    gap: 2vh;

}

.news-istanbul-title {

    font-family: "LufgaSemiBold";
    font-size: 1.7vw;

}

.news-istanbul-title span {
    background: linear-gradient(to left, rgb(130, 191, 241), rgb(30, 124, 211));
    /* Gradyan tanımı */
    -webkit-background-clip: text;
    /* Gradyanı sadece metne uygular */
    -webkit-text-fill-color: transparent;
    /* Metni saydam yaparak gradyanın görünmesini sağlar */
    position: relative;
}

.news-istanbul-description {
    width: 95%;
    background: linear-gradient(to left, rgb(145, 202, 248), rgb(7, 86, 160));
    border-radius: 80px;
    box-shadow: inset 0px 3px 8px rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    padding: 2%;
    color: #fff;
    font-family: "PoppinsMedium";
    font-size: 1.4vw;
    text-indent: 2rem;
    text-align: left;
}

.news-istanbul-video {
    width: 100%;
    position: relative;
    text-align: center;
    display: inline-block;
    margin-bottom: 5vh;
}

.video-element {
    max-width: 100%;
    border-radius: 40px;
    height: auto;
    border: #fff solid 15px;
}

.play-button {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    border: none;
    padding: 15px 25px;
    cursor: pointer;
    font-size: 22px;
    border-radius: 5px;
}

.play-button:hover {
    background-color: rgba(0, 0, 0, 0.7);
}

@media (min-width: 768px) and (max-width: 1024px) {

    .archive-home-container {
        gap: 2vh;
        /* Daha küçük boşluk */
        background-size: contain;
        /* Mobil ekran için uygun ayarla */
        padding: 10px;
        /* Kenar boşluklarını daralt */
        min-height: 120vh;
    }

    .roboRally2024 {
        padding-left: 2rem;
        padding-right: 2rem;
        height: 48px;
    }

    .roboRally2024-text {
        font-size: 3vw;
    }

    .RoborallyBirincisi-text {
        font-size: 40px;
        /* Mobil için daha uygun bir yazı boyutu */
        margin-top: 25px;
        /* Mobilde margin değerini küçült */
    }

    .roborally2024-winner-div {
        width: 95%;
        border-radius: 60px;
        padding-bottom: 8%;

    }

    .IgdirBilimSanat-text {
        font-size: 40px;
        /* Mobil için daha küçük ve okunabilir bir yazı boyutu */
    }

    .UzunYazi-text {
        font-size: 3vw;
        /* Mobil cihazlarda daha okunabilir bir font boyutu */
        margin-top: 1em;
        /* Daha dar alanlar için margin-top değerini azalt */
        text-indent: 1em;
        /* Mobil cihazlarda metin girintisini azalt */
    }

    .vector1-image {
        width: 5vw;
        /* Mobil ekranlarda daha büyük bir genişlik */
        top: 2%;
        /* Resmi biraz aşağıda konumlandır */
        left: 88%;

    }

    .vector-image {
        top: 20vw;
        /* Mobil ekranlarda biraz daha aşağıda konumlandır */
        right: 1em;
        /* Daha dar ekranlarda sağdan biraz daha içte konumlandır */
        width: 3vw;
        /* Mobil ekranlarda daha büyük bir genişlik */
    }

    .vector2-image {
        left: 2em;
        /* Daha dar ekranlarda sol tarafa daha yakın */
        width: 3vw;
        /* Mobil ekranlarda daha büyük bir genişlik */
    }

    .menu-buton {
        width: 20rem;
        font-size: 3vw;
    }

    .menu-buton-div {
        margin-top: -5%;
    }

    .news-istanbul-div {
        width: 100%;
        display: flex;
        justify-content: top;
        flex-direction: column;
        align-items: center;
        flex-wrap: wrap;
        padding: 1%;
        gap: 1vh;

    }

    .news-istanbul-title {
        font-size: 3.5vw;

    }


    .news-istanbul-description {
        width: 100%;
        border-radius: 50px;
        padding: 3%;
        font-size: 2.1vw;
        text-indent: 1rem;
    }

    .news-istanbul-video {
        width: 100%;
        position: relative;
        text-align: center;
        display: inline-block;
        margin-bottom: 5vh;
    }

    .video-element {
        max-width: 100%;
        border-radius: 20px;
        height: auto;
        border: #fff solid 10px;
    }

    .play-button {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: rgba(0, 0, 0, 0.5);
        color: white;
        border: none;
        padding: 18px 28px;
        cursor: pointer;
        font-size: 20px;
        border-radius: 5px;
    }

    .play-button:hover {
        background-color: rgba(0, 0, 0, 0.7);
    }



}

/* Ekran genişliği 768 pikselden küçükse */
@media (max-width: 768px) {

    .archive-home-container {
        gap: 1vh;
        /* Daha küçük boşluk */
        background-size: contain;
        /* Mobil ekran için uygun ayarla */
        padding: 10px;
        /* Kenar boşluklarını daralt */
        min-height: 100vh;
    }

    .roboRally2024-text {
        font-size: 3vw;
    }

    .RoborallyBirincisi-text {
        font-size: 18px;
        /* Mobil için daha uygun bir yazı boyutu */
        margin-top: 25px;
        /* Mobilde margin değerini küçült */
    }

    .roborally2024-winner-div {
        width: 85%;
        border-radius: 40px;
        padding-bottom: 5%;

    }

    .IgdirBilimSanat-text {
        font-size: 18px;
        /* Mobil için daha küçük ve okunabilir bir yazı boyutu */
    }

    .UzunYazi-text {
        font-size: 3vw;
        /* Mobil cihazlarda daha okunabilir bir font boyutu */
        margin-top: 1em;
        /* Daha dar alanlar için margin-top değerini azalt */
        text-indent: 1em;
        /* Mobil cihazlarda metin girintisini azalt */
    }

    .vector1-image {
        width: 5vw;
        /* Mobil ekranlarda daha büyük bir genişlik */
        top: 2%;
        /* Resmi biraz aşağıda konumlandır */
        left: 88%;

    }

    .vector-image {
        top: 20vw;
        /* Mobil ekranlarda biraz daha aşağıda konumlandır */
        right: 1em;
        /* Daha dar ekranlarda sağdan biraz daha içte konumlandır */
        width: 3vw;
        /* Mobil ekranlarda daha büyük bir genişlik */
    }

    .vector2-image {
        left: 2em;
        /* Daha dar ekranlarda sol tarafa daha yakın */
        width: 3vw;
        /* Mobil ekranlarda daha büyük bir genişlik */
    }

    .menu-buton {
        width: 13rem;
        font-size: 3vw;
    }

    .menu-buton-div {
        margin-top: -10%;
    }

    .news-istanbul-div {
        width: 100%;
        display: flex;
        justify-content: top;
        flex-direction: column;
        align-items: center;
        flex-wrap: wrap;
        padding: 1%;
        gap: 1vh;

    }

    .news-istanbul-title {
        font-size: 3.3vw;

    }


    .news-istanbul-description {
        width: 100%;
        border-radius: 40px;
        padding: 4%;
        font-size: 2.5vw;
        text-indent: 1rem;
    }

    .news-istanbul-video {
        width: 100%;
        position: relative;
        text-align: center;
        display: inline-block;
        margin-bottom: 5vh;
    }

    .video-element {
        max-width: 100%;
        border-radius: 20px;
        height: auto;
        border: #fff solid 10px;
    }

    .play-button {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: rgba(0, 0, 0, 0.5);
        color: white;
        border: none;
        padding: 8px 18px;
        cursor: pointer;
        font-size: 10px;
        border-radius: 5px;
    }

    .play-button:hover {
        background-color: rgba(0, 0, 0, 0.7);
    }



}
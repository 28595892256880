.notification-link-text{
    font-family: 'PoppinsSemiBold' !important;
    font-size: 0.8vw !important;
    text-decoration: underline;
}

.notification-icon{
    margin-right: 0.5rem;
}

.notification-link-icon{
    font-size: 15px;
}

@media (min-width: 768px) and (max-width: 1024px) {

    .notification-link-text{
       
        font-size: 1.2vw !important;
    }
}

@media (max-width: 768px) {

    .notification-link-text{
       
        font-size: 2vw !important;
    }
}
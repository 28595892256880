.communication-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 2%;
    background-image: url('../../public/images/robo-rally-text.png');
    background-size: cover;
    /* Resmi kapsayacak şekilde ayarlar */
    background-position: top;
    /* Resmi ortalar */
    background-repeat: no-repeat;
    /* Resmin tekrarını engeller */
    width: 100vw;
    min-height: 100vh;
}

.communication-text-div {
    width: 80%;
    min-height: 40vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    gap: 2rem;
}

.communication-text-div p:nth-child(1) {
    font-family: "PoppinsBold";
    font-size: 1.8vw;
    white-space: nowrap; /* Yazının alt satıra geçişini önler */

}

.communication-text-div p:nth-child(2) {
    font-family: "Poppins-Regular";
    font-size: 1.5vw;
    white-space: nowrap; /* Yazının alt satıra geçişini önler */


}

.communication-text-div p:nth-child(3) {
    font-family: "PoppinsSemiBold";
    font-size: 1.3vw;
    white-space: nowrap; /* Yazının alt satıra geçişini önler */

}

.communication-text-div p:nth-child(4) {
    font-family: "Poppins-Regular";
    font-size: 1.5vw;
    white-space: nowrap; /* Yazının alt satıra geçişini önler */

}

.communication-map-div {
    width: 100%;
    height: 60vh;
}

@media (min-width: 768px) and (max-width: 1024px) {

    .communication-container {
        background-size: contain;
        /* Resmi kapsayacak şekilde ayarlar */
        background-position: top;
        min-height: 140vh;
    }

    .communication-text-div {
        margin-top: 2rem;
    }

    .communication-text-div p:nth-child(1) {
        font-size: 4.5vw;
    }

    .communication-text-div p:nth-child(2) {
        font-size: 3vw;
    }

    .communication-text-div p:nth-child(3) {
        font-size: 3.5vw;
    }

    .communication-text-div p:nth-child(4) {
        font-size: 3vw;
    }
    .communication-text-div {
        width: 80%;
        height: 50vh;
  
    }
    .communication-map-div {
        width: 100%;
        height: 90vh;
    }

}

/* Ekran genişliği 768px veya daha küçük olduğunda */
@media (max-width: 768px) {

    .communication-container {
        background-size: contain;
        /* Resmi kapsayacak şekilde ayarlar */
        background-position: top;
    }

    .communication-text-div {
        margin-top: 2rem;
    }

    .communication-text-div p:nth-child(1) {
        font-size: 4.5vw;
    }

    .communication-text-div p:nth-child(2) {
        font-size: 3vw;
    }

    .communication-text-div p:nth-child(3) {
        font-size: 3.5vw;
    }

    .communication-text-div p:nth-child(4) {
        font-size: 3vw;
    }


}
.frequently-asked-questions-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 2%;
    gap: 2vh;
    flex-wrap: nowrap;
    min-height: 85vh;
    max-height: 120vh;
    overflow-y: auto;
}

.frequently-asked-questions-user-container {
    width: 98%;
    border-radius: 8px;
    padding: 15px;
    box-shadow: 1px 1px 3px rgba(150, 146, 146, 0.575);
    resize: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2vh;
}

.user-question-mid,
.user-question-detail,
.admin-question-response {
    width: 100%;
    text-align: left;
}

.user-question-mid p:first-child,
.user-question-detail p:first-child {
    font-family: "PoppinsSemiBold";
    font-size: 0.8vw;
    /* Başlığı belirginleştirir */
    margin-bottom: 1rem;
    /* Başlık ve içerik arasında boşluk bırakır */
}


.user-question-mid p:nth-child(2),
.user-question-detail p:nth-child(2) {
    display: block;
    /* WebKit için `-webkit-box` kullanımı gerekmeyebilir */
    overflow: hidden;
    /* Taşan içeriği gizler */
    text-overflow: ellipsis;
    /* Satır sonuna üç nokta ekler */
    white-space: nowrap;
    /* İçeriği tek satıra sıkıştırır */
    font-family: "PoppinsLight";
    /* Font ayarınız */
    font-size: 0.8vw;
}

.user-question-detail p:nth-child(2) {
    white-space: wrap;
    /* İçeriği tek satıra sıkıştırır */
    font-family: "PoppinsLight";
    /* Font ayarınız */
    font-size: 0.8vw;
}

.user-question-bottom {
    align-self: flex-start;
}

.user-question-bottom button {
    background-color: rgb(231, 231, 231);
    border-radius: 10px;
    border: none;
    padding: 8px 10px;
    color: rgb(99, 98, 98);
    font-family: "PoppinsRegular";
    font-size: 0.7vw;
}

.user-question-detail-imgs {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    gap: 1rem;
}

.user-question-detail-imgs img {
    width: 90%;
    aspect-ratio: 3/1;
    object-fit: contain;
}


.admin-question-response p {
    font-family: "PoppinsSemiBold";
    font-size: 0.8vw;
    text-indent: 2rem;  
}

#return-faq-question-button {
    background-color: rgb(231, 231, 231);
    border-radius: 10px;
    border: none;
    padding: 8px 14px;
    color: rgb(99, 98, 98);
    font-family: "PoppinsRegular";
    font-size: 0.9vw;
}

/* Scroll bar customization*/
.frequently-asked-questions-container::-webkit-scrollbar {
    width: 15px;
}

/* Scrollbarın arka planı */
.frequently-asked-questions-container::-webkit-scrollbar-track {
    background-color: rgba(255, 255, 255, 0.9);
    border-radius: 20px;

}

/* Scrollbarın tutamaç kısmı */
.frequently-asked-questions-container::-webkit-scrollbar-thumb {
    background: linear-gradient(to top, darkblue, #43b5df);
    /* Tutamaç köşe yuvarlaklığı */
    border-radius: 20px;
    /* Tutamaç çevresi */
    border: 2px solid #f1f1f1;


}

/* Scrollbar üzerine gelindiğinde */
.frequently-asked-questions-container::-webkit-scrollbar-thumb:hover {
    background: #035feb;
}

@media (min-width: 768px) and (max-width: 1024px) {

    .frequently-asked-questions-container {
        padding: 2%;
        gap: 1vh;
        min-height: 80vh;
        max-height: 120vh;

    }

    .frequently-asked-questions-user-container {
        width: 95%;
        border-radius: 8px;
        padding: 8px;
        gap: 1vh;

    }

    .user-question-mid,
    .user-question-detail,
    .admin-question-response {
        width: 100%;
        text-align: left;

    }

    .user-question-mid p:first-child,
    .user-question-detail p:first-child {
        font-size: 1.7vw;
        margin-bottom: 1rem;
    }


    .user-question-mid p:nth-child(2),
    .user-question-detail p:nth-child(2) {
        font-size: 1.5vw;
    }

    .user-question-detail p:nth-child(2) {
        white-space: wrap;
        /* İçeriği tek satıra sıkıştırır */
        font-family: "PoppinsLight";
        /* Font ayarınız */
        font-size: 1.5vw;
    }

    .user-question-bottom button {
        font-size: 1.7vw;
    }

    .user-question-detail-imgs {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
    }

    .user-question-detail-imgs img {
        width: 90%;
        aspect-ratio: 2/1;
    }

    .admin-question-response p {
        font-family: "PoppinsSemiBold";
        font-size: 1.5vw;
        text-indent: 1rem;

    }

    #return-faq-question-button {
        background-color: rgb(231, 231, 231);
        border-radius: 10px;
        border: none;
        padding: 8px 18px;
        color: rgb(99, 98, 98);
        font-family: "PoppinsRegular";
        font-size: 1.9vw;
    }

    /* Scroll bar customization*/
    .frequently-asked-questions-container::-webkit-scrollbar {
        width: 10px;

    }

    /* Scrollbarın arka planı */
    .frequently-asked-questions-container::-webkit-scrollbar-track {
        background-color: rgba(255, 255, 255, 0.9);
        border-radius: 30px;

    }

    /* Scrollbarın tutamaç kısmı */
    .frequently-asked-questions-container::-webkit-scrollbar-thumb {
        border-radius: 10px;
        border: 1px solid #f1f1f1;

    }

}

/* Ekran genişliği 768 pikselden küçükse */
@media (max-width: 768px) {

    .frequently-asked-questions-container {
        padding: 2%;
        gap: 1vh;
        min-height: 70vh;
        max-height: 120vh;

    }

    .frequently-asked-questions-user-container {
        width: 95%;
        border-radius: 8px;
        padding: 8px;
        gap: 1vh;

    }

    .user-question-mid,
    .user-question-detail,
    .admin-question-response {
        width: 100%;
        text-align: left;

    }

    .user-question-mid p:first-child,
    .user-question-detail p:first-child {
        font-size: 3vw;
        margin-bottom: 1rem;
    }


    .user-question-mid p:nth-child(2),
    .user-question-detail p:nth-child(2) {
        font-size: 3vw;
    }

    .user-question-detail p:nth-child(2) {
        white-space: wrap;
        /* İçeriği tek satıra sıkıştırır */
        font-family: "PoppinsLight";
        /* Font ayarınız */
        font-size: 3vw;
    }

    .user-question-bottom button {
        font-size: 2.8vw;
    }

    .user-question-detail-imgs {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
    }

    .user-question-detail-imgs img {
        width: 95%;
        aspect-ratio: 2/1;
    }

    .admin-question-response p {
        font-family: "PoppinsSemiBold";
        font-size: 2.8vw;
        text-indent: 1rem;

    }

    #return-faq-question-button {
        background-color: rgb(231, 231, 231);
        border-radius: 10px;
        border: none;
        padding: 8px 18px;
        color: rgb(99, 98, 98);
        font-family: "PoppinsRegular";
        font-size: 3vw;
    }

    /* Scroll bar customization*/
    .frequently-asked-questions-container::-webkit-scrollbar {
        width: 10px;

    }

    /* Scrollbarın arka planı */
    .frequently-asked-questions-container::-webkit-scrollbar-track {
        background-color: rgba(255, 255, 255, 0.9);
        border-radius: 30px;

    }

    /* Scrollbarın tutamaç kısmı */
    .frequently-asked-questions-container::-webkit-scrollbar-thumb {
        border-radius: 10px;
        border: 1px solid #f1f1f1;

    }

}
/* Loading.css */

.loading-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh; /* Sayfanın tamamını kapsar */
    flex-direction: column;
    text-align: center;
}

.loading-icon {
    border: 8px solid #f3f3f3; /* Arka plan rengi */
    border-top: 8px solid #0934f3; /* Spinner'ın rengi */
    border-radius: 50%;
    width: 60px;
    height: 60px;
    animation: spin 1s linear infinite;
    margin-bottom: 20px; /* İkonun altına boşluk ekler */
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

.data-container {
    padding: 20px;
}

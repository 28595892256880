.admin-ask-us-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 2%;
    background-image: url('../../public/images/robo-rally-text.png');
    background-size: contain;
    /* Resmi kapsayacak şekilde ayarlar */
    background-position: center;
    /* Resmi ortalar */
    background-repeat: no-repeat;
    /* Resmin tekrarını engeller */
    width: 100vw;
    min-height: 120vh;
}

.admin-questions-container {
    margin-top: 3%;
    width: 95%;
    border-radius: 40px;
    padding: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2vh;
    background-color: rgba(255, 255, 255, 0.4);
    box-shadow: inset 0px 2px 5px rgba(100, 100, 100, 0.9);
    min-height: 100vh;
    max-height: 150vh;
    overflow-y: auto;

}

.manage-questions-container {
    width: 98%;
    border-radius: 8px;
    padding: 15px;
    box-shadow: 1px 1px 3px rgba(126, 121, 121, 0.8);
    background-color: rgba(255, 255, 255, 0.7);
    resize: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2vh;

}

.manage-questions-top {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.manage-questions-profile {
    display: flex;
    flex-direction: row;
    align-items: center;

}

.manage-questions-profile img {
    width: 65px;
    height: 65px;
    background-color: white;
}

.manage-questions-profile ul {
    color: black;
    list-style-type: none;
    margin-left: -1vw;
    text-align: left;
    font-family: "PoppinsRegular";
    font-size: 0.9vw;
}

.manage-questions-profile ul li:nth-child(2) {
    color: rgb(122, 119, 119);
    margin-top: 0.3rem;
    font-size: 0.6vw;
}

.manage-questions-menu-container {
    position: relative;
}

.admin-question-menu-icon {
    cursor: pointer;
    font-size: 2rem;
}

.manage-questions-dropdown-menu {
    position: absolute;
    top: 60%;
    right: 1rem;
    background-color: white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    z-index: 4;
    width: 14vw;
    padding: 0.3rem 0;
}

.manage-questions-dropdown-menu ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.manage-questions-dropdown-menu ul li {
    padding: 0.6rem 1rem;
    cursor: pointer;
    font-family: "PoppinsRegular";
    font-size: 0.9vw;
    color: #333;
    transition: background-color 0.2s ease;
}

.manage-questions-dropdown-menu ul li:hover {
    background-image: linear-gradient(to right, darkblue, #3fb5e4);
    border-radius: 18px;
    color: #fff;

}

.manage-questions-mid,
.manage-question-detail {
    width: 100%;
    text-align: left;

}

.manage-questions-mid p:first-child,
.manage-question-detail p:first-child {
    font-family: "PoppinsSemiBold";
    font-size: 0.8vw;
    /* Başlığı belirginleştirir */
    margin-bottom: 1rem;
    /* Başlık ve içerik arasında boşluk bırakır */
}


.manage-questions-mid p:nth-child(2) {
    display: -webkit-box;
    /* WebKit tarayıcıları için kutu modeli */
    overflow: hidden;
    /* Taşan içeriği gizler */
    text-overflow: ellipsis;
    /* Satır sonuna üç nokta ekler */
    -webkit-box-orient: vertical;
    /* Dikey kutu yönlendirmesi */
    -webkit-line-clamp: 2;
    /* 2 satırda kes */
    font-family: "PoppinsLight";
    /* Font ayarınız */
    font-size: 0.8vw;
}

.manage-question-detail p:nth-child(2) {
    white-space: wrap;
    /* İçeriği tek satıra sıkıştırır */
    font-family: "PoppinsLight";
    /* Font ayarınız */
    font-size: 0.8vw;
}

.manage-question-detail-imgs {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    max-width: 100%;
    overflow-x: auto;
}

.manage-question-detail-imgs img {
    width: 20vw;
    height: 30vh;
    object-fit: contain;
}

.manage-question-detail-imgs img:active,
.manage-question-detail-imgs img:hover,
.manage-question-detail-imgs img:focus {
    width: 52vw;
    height: 40vh;
}


.reply-question-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2%;
    gap: 5vh;
    flex-wrap: wrap;
}

.reply-body-input {
    width: 100%;
    border-radius: 18px;
    padding: 15px;
    box-shadow: inset 0px 2px 2px 1px rgba(150, 146, 146, 0.6);
    resize: none;
    min-height: 50vh;
    font-family: 'PoppinsSemiBold';
    font-size:  0.9vw;

}

.reply-question-container p ,.manage-faq{
    width: 100%;
    /* inputların genişliği ile aynı */
    margin: 0;
    /* default marginleri sıfırlıyoruz */
    padding-left: 15px;
    /* inputların padding'ine uyumlu sol boşluk ekliyoruz */
    text-align: start;
    font-family: 'PoppinsLight';
    font-size:  0.9vw;

}

.reply-question-btn-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    /* inputların genişliği ile aynı */
}

.reply-submit-button,
.reply-img-submit-button {
    gap: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    border-radius: 10px;
    padding: 1.1vh 2.5vw;
    font-size: 1vw;
    font-family: "PoppinsRegular";
    border: none;

}

.reply-submit-button {
    background-color: rgb(99, 150, 70);

}

.reply-img-submit-button {

    background-color: rgba(28, 189, 230, 0.781);

}




/* Scroll bar customization*/
.admin-questions-container::-webkit-scrollbar {
    width: 15px;
}

/* Scrollbarın arka planı */
.admin-questions-container::-webkit-scrollbar-track {
    background-color: rgba(255, 255, 255, 0.9);
    border-radius: 20px;

}

/* Scrollbarın tutamaç kısmı */
.admin-questions-container::-webkit-scrollbar-thumb {
    background: linear-gradient(to top, darkblue, #43b5df);
    /* Tutamaç köşe yuvarlaklığı */
    border-radius: 20px;
    /* Tutamaç çevresi */
    border: 2px solid #f1f1f1;


}

/* Scrollbar üzerine gelindiğinde */
.admin-questions-container::-webkit-scrollbar-thumb:hover {
    background: #035feb;
}


@media (min-width: 768px) and (max-width: 1024px) {

    .admin-ask-us-container { 
        background-position: top;   
        width: 100vw;
        min-height: 140vh;
    }

    .admin-questions-container {   
        border-radius: 20px;
        padding: 10px;
        gap: 1vh;
        min-height: 80vh;
        max-height: 130vh;
    
    }
    
    .manage-questions-container {
        width: 95%;
        border-radius: 5px;
        padding: 10px;     
        resize: none;
        gap: 1vh;
    
    }   
    
    
    .manage-questions-profile img {
        width: 50px;
        height: 50px;
        border-radius: 50%;
    }
    
    .manage-questions-profile ul {
        margin-left: -3vw;
        font-size: 1.7vw;
    }
    
    .manage-questions-profile ul li:nth-child(2) {
        color: rgb(122, 119, 119);
        margin-top: 0.1rem;
        font-size: 1.3vw;
    }
    
   
    .admin-question-menu-icon {
        cursor: pointer;
        font-size: 1.5rem;
    }
    
    .manage-questions-dropdown-menu {
        position: absolute;
        top: 40%;
        right: 0.5rem;
        background-color: white;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        border-radius: 4px;
        z-index: 4;
        width: 30vw;
        padding: 0.2rem 0;
    }
    
    .manage-questions-dropdown-menu ul li {
        padding: 0.5rem 0.5rem;
        cursor: pointer;
        font-family: "PoppinsRegular";
        font-size: 2vw;
        color: #333;
        transition: background-color 0.2s ease;
    }
    
    .manage-questions-dropdown-menu ul li:hover {
        background-image: linear-gradient(to right, darkblue, #3fb5e4);
        border-radius: 10px;
        color: #fff;
    
    }
    
    .manage-questions-mid,
    .manage-question-detail {
        width: 100%;
        text-align: left;
    
    }
    
    .manage-questions-mid p:first-child,
    .manage-question-detail p:first-child {
        font-family: "PoppinsSemiBold";
        font-size: 1.7vw;
        /* Başlığı belirginleştirir */
        margin-bottom: 1rem;
        /* Başlık ve içerik arasında boşluk bırakır */
    }
    
    
    .manage-questions-mid p:nth-child(2) {        
        /* Font ayarınız */
        font-size: 1.5vw;
    }
    
    .manage-question-detail p:nth-child(2) {
        font-size: 1.5vw;
    }
    
    .manage-question-detail-imgs {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        max-width: 100%;
        overflow-x: auto;
        gap: 1rem;
    }
    
    .manage-question-detail-imgs img {
        width: 50vw;
        height: 10vh;
        object-fit: contain;
    }
    
    .manage-question-detail-imgs img:active,
    .manage-question-detail-imgs img:hover,
    .manage-question-detail-imgs img:focus {
        width: 85vw;
        height: 40vh;
    }
    
    
    .reply-question-container {   
        padding: 1%;
        gap: 1vh;
    }
    
    .reply-body-input {
        border-radius: 10px;
        padding: 10px;
        resize: none;
        min-height: 50vh;
        font-family: 'PoppinsSemiBold';
        font-size:  1.5vw;
    
    }
    
    .reply-question-container p ,.manage-faq{ 
        padding-left: 10px;     
        font-size:  1.8vw;
    
    }
    
    
    .reply-submit-button,
    .reply-img-submit-button {
        gap: 1rem; 
        border-radius: 5px;
        padding: 1.1vh 3vw;
        font-size: 2vw;
     
    
    }
      
    
    .selected-files-container {
        max-width: 95%;
        padding: 0.5rem;
        border-radius: 5px;
        gap: 1rem;
      
    }    

    
    /* Scroll bar customization*/
    .admin-questions-container::-webkit-scrollbar {
        width: 10px;
    }
    
    /* Scrollbarın arka planı */
    .admin-questions-container::-webkit-scrollbar-track {
        background-color: rgba(255, 255, 255, 0.9);
        border-radius: 20px;
    
    }
    
    /* Scrollbarın tutamaç kısmı */
    .admin-questions-container::-webkit-scrollbar-thumb {
        background: linear-gradient(to top, darkblue, #43b5df);
        /* Tutamaç köşe yuvarlaklığı */
        border-radius: 20px;
        /* Tutamaç çevresi */
        border: 2px solid #f1f1f1;
    
    
    }
    
    /* Scrollbar üzerine gelindiğinde */
    .admin-questions-container::-webkit-scrollbar-thumb:hover {
        background: #035feb;
    }
}


/* Ekran genişliği 768 pikselden küçükse */
@media (max-width: 768px) {
    
    .admin-ask-us-container { 
        background-position: top;   
    }
    
    .admin-questions-container {   
        border-radius: 20px;
        padding: 10px;
        gap: 1vh;
        min-height: 80vh;
        max-height: 120vh;
    
    }
    
    .manage-questions-container {
        width: 98%;
        border-radius: 5px;
        padding: 10px;     
        resize: none;
        gap: 1vh;
    
    }   
    
    
    .manage-questions-profile img {
        width: 45px;
        height: 45px;
        border-radius: 50%;
    }
    
    .manage-questions-profile ul {
        margin-left: -5vw;
        font-size: 3vw;
    }
    
    .manage-questions-profile ul li:nth-child(2) {
        color: rgb(122, 119, 119);
        margin-top: 0.1rem;
        font-size: 1.9vw;
    }
    
   
    .admin-question-menu-icon {
        cursor: pointer;
        font-size: 1rem;
    }
    
    .manage-questions-dropdown-menu {
        position: absolute;
        top: 40%;
        right: 0.5rem;
        background-color: white;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        border-radius: 4px;
        z-index: 4;
        width: 40vw;
        padding: 0.3rem 0;
    }
    
    .manage-questions-dropdown-menu ul li {
        padding: 0.5rem 1rem;
        cursor: pointer;
        font-family: "PoppinsRegular";
        font-size: 3vw;
        color: #333;
        transition: background-color 0.2s ease;
    }
    
    .manage-questions-dropdown-menu ul li:hover {
        background-image: linear-gradient(to right, darkblue, #3fb5e4);
        border-radius: 10px;
        color: #fff;
    
    }
    
    .manage-questions-mid,
    .manage-question-detail {
        width: 100%;
        text-align: left;
    
    }
    
    .manage-questions-mid p:first-child,
    .manage-question-detail p:first-child {
        font-family: "PoppinsSemiBold";
        font-size: 3vw;
        /* Başlığı belirginleştirir */
        margin-bottom: 1rem;
        /* Başlık ve içerik arasında boşluk bırakır */
    }
    
    
    .manage-questions-mid p:nth-child(2) {        
        /* Font ayarınız */
        font-size: 3vw;
    }
    
    .manage-question-detail p:nth-child(2) {
        font-size: 3vw;
    }
    
    .manage-question-detail-imgs {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        max-width: 100%;
        overflow-x: auto;
        gap: 1rem;
    }
    
    .manage-question-detail-imgs img {
        width: 50vw;
        height: 10vh;
        object-fit: contain;
    }
    
    .manage-question-detail-imgs img:active,
    .manage-question-detail-imgs img:hover,
    .manage-question-detail-imgs img:focus {
        width: 85vw;
        height: 40vh;
    }
    
    
    .reply-question-container {   
        padding: 1%;
        gap: 1vh;
    }
    
    .reply-body-input {
        border-radius: 10px;
        padding: 10px;
        resize: none;
        min-height: 50vh;
        font-family: 'PoppinsSemiBold';
        font-size:  3vw;
    
    }
    
    .reply-question-container p ,.manage-faq{ 
        padding-left: 10px;     
        font-size:  3vw;
    
    }
    
    
    .reply-submit-button,
    .reply-img-submit-button {
        gap: 1rem; 
        border-radius: 8px;
        padding: 1.1vh 4vw;
        font-size: 3vw;   
    
    }
    
    
    /* Scroll bar customization*/
    .admin-questions-container::-webkit-scrollbar {
        width: 15px;
    }
    
    /* Scrollbarın arka planı */
    .admin-questions-container::-webkit-scrollbar-track {
        background-color: rgba(255, 255, 255, 0.9);
        border-radius: 20px;
    
    }
    
    /* Scrollbarın tutamaç kısmı */
    .admin-questions-container::-webkit-scrollbar-thumb {
        background: linear-gradient(to top, darkblue, #43b5df);
        /* Tutamaç köşe yuvarlaklığı */
        border-radius: 20px;
        /* Tutamaç çevresi */
        border: 2px solid #f1f1f1;
    
    
    }
    
    /* Scrollbar üzerine gelindiğinde */
    .admin-questions-container::-webkit-scrollbar-thumb:hover {
        background: #035feb;
    }
}
.my-modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(255, 255, 255, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
    width: 100vw;
    height: 100vh;
}

.my-modal-close-button {
    position: absolute;
    top: 1.2vw;
    right: 1.2vw;
    background: #ff5f5f;
    border: none;
    color: white;
    border-radius: 50%;
    width: calc(100vw * 0.03);
    height: calc(100vh * 0.06);
    cursor: pointer;
    z-index: 1000;

}

.my-modal-content {
    max-width: 50%;
    position: relative;
    background-color: rgba(255, 255, 255, 0.9);
    border-radius: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 2%;
    box-shadow: inset 0px 4px 5px rgba(100, 100, 100, 0.5);

}

.my-modal-body {
    width: 100%;
    position: relative;
    color: #030303;
    display: flex;
    flex-direction: column;
    line-height: 1.7;
    text-align: start;
    padding: 2%;

}

.my-modal-body p:nth-child(1) {

    font-size: 1.4vw;
    font-family: "DMSansBold";
}

.my-modal-body p:nth-child(2), p:nth-child(3), p:nth-child(4) {

    font-size: 1.3vw;
    font-family: "DMSansRegular";
}

.my-modal-confirm-button {
    background: #d83535 !important;
    color: white;
    margin-right: 5px;
}

.my-modal-refuse-button {
    background: #02cc34 !important;
    color: white;
    margin-left: 5px;
}

@media (min-width: 768px) and (max-width: 1024px) {

    .my-modal {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba(255, 255, 255, 0.5);
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 1000;
        width: 100vw;
        /* Tüm ekran genişliği */
        height: 100vh;
        /* Tüm ekran yüksekliği */
    }

    .my-modal-close-button {
        position: absolute;
        top: 1.2vw;
        right: 1.2vw;
        background: #ff5f5f;
        border: none;
        color: white;
        border-radius: 50%;
        width: calc(100vw * 0.06);
        height: calc(100vh * 0.04);
        cursor: pointer;
        z-index: 1000;
    }

    .my-modal-content {
        max-width: 90%;
        border-radius: 20px;
        display: flex;
        padding: 2%;

    }

    .my-modal-body {
        max-height: 20vh;
        padding: 1%;

    }

    .my-modal-body p:nth-child(1) {

        font-size: 3.2vw;
    }

    .my-modal-body p:nth-child(2), p:nth-child(3), p:nth-child(4) {
        font-size: 2.8vw;
    }

}


/* Ekran genişliği 768px veya daha küçük olduğunda */
@media (max-width: 768px) {  

    .my-modal-close-button {
        position: absolute;
        top: 1.2vw;
        right: 1.2vw;
        background: #ff5f5f;
        border: none;
        color: white;
        border-radius: 50%;
        width: calc(100vw * 0.10);
        height: calc(100vh * 0.05);
        cursor: pointer;
        z-index: 1000;
    }

    .my-modal-content {
        max-width: 90%;
        border-radius: 20px;
        display: flex;
        padding: 2%;
    }

    .my-modal-body {
        max-height: 100%;
        padding: 1%;
    }

    .my-modal-body p:nth-child(1) {
        font-size: 3.8vw;
    }

    .my-modal-body p:nth-child(2), p:nth-child(3), p:nth-child(4) {
        font-size: 3.6vw;
    }

}
.navbar-container {
    width: 92%;
    height: calc(100vh * 0.09);
    background-color: black;
    border-radius: 50px;
    border: 4px ridge black;
    margin-top: 2%;
    display: flex;
    flex-wrap: nowrap;

}

.navbar-menu {
    width: 70%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-evenly;
    align-items: center;
    color: white;
    list-style-type: none;
    margin: 0;
    padding: 0;

}

.navbar-logo {
    width: 30%;
    display: flex;
    justify-content: flex-start;
    margin-left: 2%;
    height: auto;
}

.main-nav_item {
    display: inline-block;
    text-align: right;
}

.main-nav_item:last-child {
    display: none;
}

.arsiv-menu,
.organizasyon-menu,
.user-menu {
    position: absolute;
    top: 120%;
    left: 50%;
    transform: translateX(-50%);
    background-color: white;
    color: black;
    list-style-type: none;
    padding: 15px;
    margin: 0;
    border-radius: 40px;
    min-width: 280px;
    box-shadow: inset 0px 6px 5px rgba(100, 100, 100, 0.9);
    /* Beyaz iç kenar gölgeliği */
    text-align: center;
    z-index: 1000;
    padding: 20px;
    font-weight: bold;
    cursor: pointer;
    font-family: "PoppinsRegular";
    font-size: 1vw;
}

.user-menu {
    left: 50%;
    top: 150%;
}

.user-menu span {
    padding: 10px 35px 10px 35px;
    box-shadow: inset 0px 6px 5px rgba(100, 100, 100, 0.6);
    border-radius: 40px;
    margin-bottom: 2vh;
    display: inline-block;
    /* Span içinde blok seviyesinde davranış */
    white-space: wrap;
    font-size: 1vw;
    /* Dinamik font boyutu, ekran genişliğine göre küçülür */
    max-width: 250px;
    /* İsim genişliği buton genişliği ile sınırlı */
}

.arsiv-menu li:not(:last-child),
.organizasyon-menu li:not(:last-child),
.user-menu li:not(:last-child) {
    padding: 5px 1px;
    border-bottom: 3px solid #DEE2E3;
}

.arsiv-menu li:hover,
.organizasyon-menu li:hover,
.user-menu li:hover {
    border-radius: 40px;
    background-image: linear-gradient(to right, darkblue, #1bb2ef);
}

.navbar-button {
    border: none;
    border-radius: 40px;
    padding: 0 1vw;
    color: white;
    cursor: pointer;
    white-space: nowrap;
    font-family: "PoppinsRegular";
    font-size: 1.1vw;
    height: calc(100vh * 0.08);
}

.user-info-button {
    background-color: white;
    height: calc(100vh * 0.06);

}

.navbar-user-info {
    color: black;
    font-family: "PoppinsRegular";
    white-space: nowrap;
    overflow: hidden;
    font-weight: bold;
    font-size: 2vw;
    padding: 0rem 2rem;

}

/* Tablet moduna özgü stil ayarları */
@media (min-width: 768px) and (max-width: 1024px) {

    .navbar-container {
        /* Yükseklik içeriğe göre artsın */
        min-height: calc(100vh * 0.06);
    }

    .navbar-container.responsive {
        /* Menünün genişlemesiyle yükseklik içeriğe göre ayarlanacak */
        height: auto;
    }

    .navbar-logo {
        display: flex;
        justify-content: flex-start;
        margin-left: 2rem;
        margin-top: 2rem;
        height: 3rem;
    }

    .main-nav_item {
        display: none;
        width: calc(100vw * 0.40);

    }

    .main-nav_item:last-child {
        display: block;
    }

    .responsive .main-nav_item {
        display: block;
        text-align: left;
        margin: 2rem 2rem;

    }

    .main-nav_item:last-child {
        position: absolute;
        right: 5rem;
        top: 3rem;

    }

    .responsive .main-nav_item:last-child {
        width: calc(100vw * 0.2);
        position: absolute;
        top: -0.1rem;
        text-align: right;

    }

    .navbar-menu {
        display: block;
        flex-direction: column;
        flex-wrap: wrap;
        justify-content: space-evenly;
        list-style-type: none;
    }

    .navbar-button {
        border: none;
        border-radius: 30px;
        color: white;
        cursor: pointer;
        white-space: nowrap;
        font-family: "PoppinsRegular";
        font-size: 3vw;
        height: calc(100vh * 0.04);
    }



    .arsiv-menu,
    .organizasyon-menu,
    .user-menu {
        position: absolute;
        transform: translateX(-50%);
        background-color: white;
        color: black;
        list-style-type: none;
        top: 3.5rem;
        margin-left: -1rem;
        border-radius: 30px;
        min-width: 18rem;
        box-shadow: inset 0px 6px 5px rgba(100, 100, 100, 0.9);
        /* Beyaz iç kenar gölgeliği */
        text-align: center;
        z-index: 1000;
        padding: 1rem;
        font-weight: bold;
        cursor: pointer;
        font-family: "PoppinsRegular";
        font-size: 2.2vw;
    }

    .user-menu {
        left: 45%;
    }

    .user-menu span {
        padding: 10px 20px;
        font-size: 2.2vw;
        /* Dinamik font boyutu, ekran genişliğine göre küçülür */
        max-width: 180px;
        /* İsim genişliği buton genişliği ile sınırlı */

    }

    .user-info-button {
        height: calc(100vh * 0.04);

    }

    .navbar-user-info {
        color: black;
        font-family: "PoppinsRegular";
        white-space: nowrap;
        overflow: hidden;
        font-weight: bold;
        font-size: 4vw;
        padding: 0.1rem 2rem;

    }


}



/* phones */
@media (max-width: 768px) {

    .navbar-container {
        /* Yükseklik içeriğe göre artsın */
        min-height: calc(100vh * 0.09);
    }

    .navbar-container.responsive {
        /* Menünün genişlemesiyle yükseklik içeriğe göre ayarlanacak */
        height: auto;
    }

    .navbar-logo {
        display: flex;
        justify-content: flex-start;
        margin-left: 2rem;
        margin-top: 1rem;
        height: 2rem;
    }

    .main-nav_item {
        display: none;
        width: calc(100vw * 0.45);

    }

    .main-nav_item:last-child {
        display: block;
    }

    .responsive .main-nav_item {
        display: block;
        text-align: left;
        margin: 2rem 1rem;

    }

    .main-nav_item:last-child {
        position: absolute;
        right: 3rem;
        top: 1.5rem;

    }

    .responsive .main-nav_item:last-child {
        width: calc(100vw * 0.1);
        position: absolute;
        top: -0.6rem;
        text-align: right;

    }

    .navbar-menu {
        display: block;
        flex-direction: column;
        flex-wrap: wrap;
        justify-content: space-evenly;
        list-style-type: none;
    }

    .navbar-button {
        border: none;
        border-radius: 40px;
        color: white;
        cursor: pointer;
        white-space: nowrap;
        font-family: "PoppinsRegular";
        font-size: 3.5vw;
        height: calc(100vh * 0.04);
    }



    .arsiv-menu,
    .organizasyon-menu,
    .user-menu {
        position: absolute;
        transform: translateX(-50%);
        background-color: white;
        color: black;
        list-style-type: none;
        top: 3rem;
        margin-left: -0.8rem;
        border-radius: 40px;
        min-width: 12rem;
        box-shadow: inset 0px 6px 5px rgba(100, 100, 100, 0.9);
        /* Beyaz iç kenar gölgeliği */
        text-align: center;
        z-index: 1000;
        padding: 1rem;
        font-weight: bold;
        cursor: pointer;
        font-family: "PoppinsRegular";
        font-size: 2.8vw;
    }

    .user-menu {
        left: 40%;
    }

    .user-menu span {
        padding: 10px 25px;
        font-size: 3.2vw;
        /* Dinamik font boyutu, ekran genişliğine göre küçülür */
        max-width: 180px;
        /* İsim genişliği buton genişliği ile sınırlı */

    }

    .user-info-button {
        height: calc(100vh * 0.04);

    }

    .navbar-user-info {
        color: black;
        font-family: "PoppinsRegular";
        white-space: nowrap;
        overflow: hidden;
        font-weight: bold;
        font-size: 4.5vw;
        padding: 0.1rem 2rem;

    }

}
.apply-exam-container {
    display: flex;
    flex-direction: column;
    gap: 6rem;
    align-items: center;
    background-image: url('../../public/images/robo-rally-text.png');
    background-size: contain;
    /* Resmi kapsayacak şekilde ayarlar */
    background-position: center;
    /* Resmi ortalar */
    background-repeat: no-repeat;
    /* Resmin tekrarını engeller */
    width: 100vw;
    min-height: 120vh;

}

.user-name-div {
    margin-top: 5%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.5);
    box-shadow: inset 0px 2px 5px rgba(100, 100, 100, 0.7);
    height: 8vh;
    border-radius: 40px;
}


.user-name-div p:nth-child(1) {
    font-family: "PoppinsLight";
    font-size: 1.67vw;
    margin: 0;
    /* Varsayılan margin boşluklarını kaldır */
    padding: 0 20px 0 30px;

}

.user-name-div p:nth-child(2) {
    font-family: "PoppinsMedium";
    font-size: 1.67vw;
    margin: 0;
    padding: 0 30px 0 0;
}

.exam-intro-div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.exam-intro-div p:nth-child(1) {
    font-family: "UrbanistMedium";
    font-size: 2.3vw;

}

.exam-intro-div p:nth-child(2) {
    font-family: "PoppinsLight";
    font-size: 1.5vw;
    margin: 0;
    /* Varsayılan margin boşluklarını kaldır */
    line-height: 3rem;
}

.apply-exam-btn {
    margin-top: 5%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.5);
    box-shadow: inset 0px 2px 5px rgba(100, 100, 100, 0.6);
    height: 8vh;
    border-radius: 40px;
    cursor: pointer;
}

.apply-exam-btn p:nth-child(1) {
    font-family: "PoppinsMedium";
    font-size: 1.6vw;
    margin: 0;
    /* Varsayılan margin boşluklarını kaldır */
    padding: 0 40px;
    /* Her iki yana eşit padding ver */
}


.exam-description-div {
    width: 70%;
    margin: 0 auto;
    text-align: left;
    margin-bottom: 4%;
}

.exam-description-title {
    font-family: "PoppinsMedium" !important;
    font-size: 1.25vw !important;

}

.exam-description-div span {
    font-family: "PoppinsMedium";
    font-size: 1.05vw;
    white-space: normal;


}

.exam-description-div p {
    margin-bottom: 1em;
    /* Paragraflar arası boşluk */
    font-family: "PoppinsRegular";
    font-size: 1.05vw;
    line-height: 1.8;
    /* Satır yüksekliği */
    word-wrap: break-word;
    /* Uzun metinler alt satıra geçer */
    white-space: normal;
    /* Satırların kırılmasını sağlar */

}

@media (min-width: 768px) and (max-width: 1024px) {

    .apply-exam-container {
        gap: 5rem;
        min-height: 120vh;
        background-size: contain;
        /* Resmi kapsayacak şekilde ayarlar */
        background-position: top;

    }

    .user-name-div {
        height: 6vh;
        border-radius: 40px;
    }


    .user-name-div p:nth-child(1) {
        font-family: "PoppinsLight";
        font-size: 3vw;
        padding: 0 10px 0 35px;

    }

    .user-name-div p:nth-child(2) {
        font-family: "PoppinsMedium";
        font-size: 3vw;
        padding: 0 30px 0 0;
    }



    .exam-intro-div p:nth-child(1) {
        font-size: 3.5vw;

    }

    .exam-intro-div p:nth-child(2) {
        font-size: 2.2vw;
        line-height: 1.8;
    }

    .apply-exam-btn {
        height: 6vh;
        border-radius: 50px;
    }

    .apply-exam-btn p:nth-child(1) {
        padding: 0 40px;
        font-size: 3vw;
    }


    .exam-description-div {
        width: 85%;
        margin: 0 auto;
        text-align: left;
    }

    .exam-description-title {
        font-size: 3vw !important;

    }

    .exam-description-div span {
        font-size: 2.5vw;
        white-space: normal;

    }

    .exam-description-div p {
        margin-bottom: 0.5em;
        font-size: 2.5vw;
        line-height: 1.6;

    }   
    
   

}
/* Ekran genişliği 768px veya daha küçük olduğunda */
@media (max-width: 768px) {

    .apply-exam-container {
        gap: 3rem;
        min-height: 90vh;
        background-size: contain;
        /* Resmi kapsayacak şekilde ayarlar */
        background-position: top;

    }

    .user-name-div {
        height: 6vh;
        border-radius: 40px;
    }


    .user-name-div p:nth-child(1) {
        font-family: "PoppinsLight";
        font-size: 3.5vw;
        padding: 0 10px 0 30px;

    }

    .user-name-div p:nth-child(2) {
        font-family: "PoppinsMedium";
        font-size: 3.5vw;
        padding: 0 30px 0 0;
    }



    .exam-intro-div p:nth-child(1) {
        font-size: 3.8vw;

    }

    .exam-intro-div p:nth-child(2) {
        font-size: 3vw;
        line-height: 1.5rem;
    }

    .apply-exam-btn {
        height: 6vh;
        border-radius: 30px;
    }

    .apply-exam-btn p:nth-child(1) {
        padding: 0 20px;
        font-size: 3.5vw;
    }


    .exam-description-div {
        width: 80%;
        margin: 0 auto;
        text-align: left;
        margin-bottom: 4%;
    }

    .exam-description-title {
        font-size: 3.25vw !important;

    }

    .exam-description-div span {
        font-size: 3.05vw;
        white-space: normal;


    }

    .exam-description-div p {
        margin-bottom: 0.5em;
        font-size: 3.05vw;
        line-height: 1.6;

    }   
    
   

}
.exam-bg-img {
  text-align: center;
  justify-content: center;
  display: flex;
  background-image: url('../../public/images/robo-rally-text.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100vw;
  height: 100vh;
  position: fixed;
  overflow: auto;
}

.exam-container {
  width: 75%;
}

.exam-card {
  background-color: #f9f9f9;
  border-radius: 15px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.exam-stepper-img {
  margin: 0.5rem;
  width: 80%;
  padding: 1rem;
}

.exam-icon {
  margin-right: 0.5rem;
}

.exam-question-card {
  margin-top: 1.5rem;
  margin-bottom: 2rem;
  text-align: left;
  padding: 2rem;
}

.exam-question-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.exam-question-number-count {
  display: flex;
  align-items: center;
}

.exam-question-number {
  margin: 0 10px 0 0;
  font-size: 1.5rem;
  font-family: 'PoppinsRegular';
}

.exam-question-count {
  font-size: 1rem;
  font-family: 'PoppinsRegular';
}

.exam-time-left {
  margin-left: auto;
  text-decoration: underline;
  font-family: 'PoppinsMedium';
  color: red;
  font-size: 1.5rem;
}

.exam-question-container.vertical-layout {
  display: block;
}

.exam-question-container.horizontal-layout {
  display: flex;
}

.exam-question-imgs,
.exam-question-text-container {
  width: 100%;
}

.exam-question-imgs,
.exam-question-text-container {
  width: 95%;
}

.horizontal-layout .exam-question-imgs {
  width: 45%; 
  margin-left: 2rem;
}

.horizontal-layout .exam-question-text-container {
  width: 55%; 
  margin-left: -4rem;
  margin-top: 2rem;
}

.exam-question-imgs {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 1rem;
  margin: 0.5rem;
}

.exam-question-imgs img {
  width: 100%;
  aspect-ratio: 3/1;
  object-fit: contain;
}

/* .exam-question-imgs img:active,
.exam-question-imgs img:hover,
.exam-question-imgs img:focus {
  aspect-ratio: 2/1;
  width: 110%;
} */

.horizontal-layout .exam-question-imgs img {
  width: 110%;
  aspect-ratio: 3/3; 
  object-fit: contain;
}

.horizontal-layout .exam-question-imgs img:active,
.horizontal-layout .exam-question-imgs img:hover,
.horizontal-layout .exam-question-imgs img:focus {
  aspect-ratio: 2/2;
  width: 110%;
}

.exam-question-text {
  font-size: 1.2rem;
  white-space: pre-line;
  text-align: justify
}

.exam-question-input {
  width: 100%;
  min-height: 25vh;
  border-radius: 40px;
  padding: 15px;
  box-sizing: border-box;
  line-height: 1.5;
  vertical-align: top;
  resize: none;
  vertical-align: top;
  font-family: 'PoppinsLight';
  font-size: 1rem;
}

.exam-card ul {
  list-style-type: disc;

}

.exam-question-btn {
  width: 100%;
  align-items: center;
  background-color: #cacaca;
  color: white;
  border-radius: 45px;
  padding: 0.7rem;
  font-family: 'PoppinsRegular';
  font-size: 1.3rem;
}

.exam-question-btn:hover {
  background-color: #a3a3a3;
  color: white;
  font-family: 'PoppinsRegular';
  font-size: 1.3rem;
}

.exam-question-btn-dark {
  width: 100%;
  align-items: center;
  background-color: #a3a3a3;
  color: white;
  border-radius: 45px;
  padding: 0.7rem;
  font-family: 'PoppinsRegular';
  font-size: 1.3rem;
}

.exam-question-btn-dark:hover {
  background-color: #a3a3a3;
  color: white;
  font-family: 'PoppinsRegular';
  font-size: 1.3rem;
}

.exam-select-pdf {
  width: 100%;
}

.exam-select-pdf-input {
  display: none;
}

.exam-select-pdf-card {
  min-width: 50%;
  height: 8rem;
  padding: 0;
  padding-right: 2rem;
}

.exam-select-pdf-card-body {
  align-items: center;
  padding: 0;
  width: 100%;
}

.exam-select-pdf-badge {
  height: 100%;
  width: 7rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px 0 0 20px;
  font-size: larger;
}

.exam-select-pdf-card-badge {
  height: 100%;
}

.exam-select-pdf-card-title {
  display: flex;
  flex-grow: 1;
  justify-content: space-between;
  min-width: 0;
}

.text-truncate-container {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  min-width: 0;
}

.exam-select-pdf-card-text {
  font-size: 1.5rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  min-width: 0;
}

.exam-select-pdf-card-date-text {
  font-size: 1rem;
}

.exam-select-pdf-card-button {
  margin-right: 0.3rem;
  font-size: 2rem;
  margin-left: auto;
  flex-shrink: 0;
}

.exam-finish-text {
  text-align: center;
  margin-top: 5rem;
  margin-bottom: 3rem;
  font-size: 1rem;
}

.poppins-regular-text {
  font-family: 'PoppinsRegular';
}

.poppins-mediun-text {
  font-family: 'PoppinsMedium';
}

.poppins-light-text {
  font-family: 'PoppinsLight';
}

.poppins-bold-text {
  font-family: 'PoppinsBold';
}

.exam-modal-body {
  max-height: 25vh;
  position: relative;
  padding: 1%;
  color: #030303;
  display: flex;
  flex-direction: column;
  line-height: 1.7;
  text-align: start;
}

@media (max-width: 768px) {

  .exam-bg-img {
    margin: 1rem 0;
    text-align: center;
    justify-content: center;
    display: flex;
    background-image: url('../../public/images/robo-rally-text.png');
    background-size: contain;
    background-position: top;
    background-repeat: no-repeat;
    height: 100%;
    width: 100vw;
    padding-right: 0rem;
    padding-left: 0rem;
  }

  .exam-container{
    width: 90%;
  }

  .exam-stepper-img {
    margin: 0.5rem;
    width: 100%;
    padding: 0;
  }

  .exam-question-btn{
    padding: 0.5;
    font-size: 1rem;
  }

  .exam-select-pdf-card {
    height: 6rem;
  }

  .exam-select-pdf-badge{
    width: 5rem;
  }

  .exam-select-pdf-card-text{
    font-size: 1rem;
  }

  .exam-select-pdf-card-button {
    margin-right: 0;
  }

  .exam-question-number {
    margin: 0 2px 0 0;
    font-size: 1rem;
  }
  
  .exam-question-count {
    font-size: 0.7rem;
  }

  .exam-icon{
    width: 0.7rem;
  }

  .exam-finish-text {
    margin-top: 3rem;
    margin-bottom: 2rem;
  }

}
.my-team-bg {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    background-image: url('../../public/images/robo-rally-text.png');
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    width: 100vw;
    min-height: 110vh;   
}

.my-team-container {
    width: 30%;
    background-color: rgba(255, 255, 255, 0.5);
    border-radius: 80px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 4vh;
    box-shadow: inset 0px 4px 3px rgba(0, 0, 0, 0.4);
    flex-wrap: wrap;
    padding: 3% 4%;
    margin-top: 12vh;
    margin-bottom: 5vh;

}

.title-card{
    width: 100%;
    background-color: rgba(255, 255, 255, 0.8);
    box-shadow: inset 0px 4px 3px rgba(0, 0, 0, 0.4);
    border-radius: 70px;
    padding: 5% 10%;

}

.title-card span{
    font-size: 1.6vw;
    font-family:"PoppinsMedium" ;
}

.team-student-container{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.team-student-container span:nth-child(1){
   color: black;
   font-size: 1.5vw;
   font-family:"PoppinsMedium" ;
   text-align: left;
}

.team-student-container span:nth-child(2){
    color: black;
    font-size: 1.3vw;
    font-family:"LufgaMedium" ;
    text-align: left;

 }

 .team-student-container span:nth-child(3){
    color: rgb(119, 119, 119);
    font-size: 1.3vw;
    font-family:"LufgaMedium" ;
    text-align: left;

 }

.team-student-container label{
    padding-left:7%; 
    padding-bottom: 1%;
    color: rgb(119, 119, 119);
    font-size: 1.2vw;
    font-family:"PoppinsMedium" ;
}

.team-student-card{
    width: 100%;
    background-color: rgba(255, 255, 255, 0.8);
    box-shadow:  0px 4px 8px rgba(0, 0, 0, 0.4);
    border-radius: 30px;
    padding-left:10%; 
    padding-top: 3%;
    text-align: start;
    color: black;

}

.team-student-name{
    font-size: 1vw;
    font-family:"PoppinsMedium" ;
    text-transform: uppercase;

}

.team-student-tc{
    font-size: 1vw;
    font-family:"PoppinsLight" ;

}

.team-update-btn {
    background-color: #8f9396;
    color: #ffffff;
    border-radius: 15px;
    padding-top: 1.2rem;
    padding-bottom: 1.2rem;
    width: 13vw;
    font-Family: "PoppinsMedium";
    font-size: 1.04vw;
    border: none;
}

.team-students-btns{
    width: 100%;
    background-color: rgba(255, 255, 255, 0.8);
    box-shadow:  inset 0px 4px 8px rgba(0, 0, 0, 0.4);
    border-radius: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.team-student-active{
    width: 100%;
    border-radius: 20px;
    background-color: rgba(255, 255, 255, 0.9);
    box-shadow:   0px 4px 10px rgba(0, 0, 0, 0.8);
    border: none;
    padding-top: 2%;
    padding-bottom: 2%;
    color: rgb(119, 119, 119);
    font-size: 1vw;
    font-family:"PoppinsMedium" ;
}

.team-student-pasive{
    width: 100%;
    border-radius: 20px;
    background-color: rgba(255, 255, 255, 0.01);
    box-shadow:   0px 1px 1px rgba(0, 0, 0, 0.1);
    border: none;
    padding-top: 2%;
    padding-bottom: 2%;
    color: rgb(177, 174, 174);
    font-size: 1vw;
    font-family:"PoppinsMedium" ;
}

.team-update-desc{
    color:#333;
    font-size: 0.8vw;
    font-family:"PoppinsRegular" ;
}

.team-update-form{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}


.mb-3 {
    margin-top: 3rem;
}

.team-update-label {
    display: block;
    width: 25vw;
    font-family: "PoppinsRegular";
    font-size: 1vw;
    text-align: start;
}

.team-update-input {
    border: none;
    border-bottom: 2px solid #000;
    border-radius: 0;
    background-color: transparent !important;
    width: 25vw;
    font-size: 1.3rem;

}

@media (min-width: 768px) and (max-width: 1024px) {

    .my-team-bg {
        background-position: top;
       
    }
    
    .my-team-container {
        width: 80%;
        background-color: rgba(255, 255, 255, 0.5);
        border-radius: 50px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 4vh;
        box-shadow: inset 0px 4px 3px rgba(0, 0, 0, 0.4);
        flex-wrap: wrap;
        padding: 3% 4%;
        margin-top: 5vh;
        margin-bottom: 6vh;
    
    }
    
    .title-card{
        width: 70%;
        background-color: rgba(255, 255, 255, 0.8);
        box-shadow: inset 0px 4px 3px rgba(0, 0, 0, 0.4);
        border-radius: 50px;
        padding: 5% 8%;
    
    }
    
    .title-card span{
        font-size: 3.5vw;
    }
    
    .team-student-container{
        width: 90%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }
    
    .team-student-container span:nth-child(1){
       font-size:3.5vw;
    }
    
    .team-student-container span:nth-child(2){
        font-size: 2.8vw;     
    
     }
    
     .team-student-container span:nth-child(3){
        color: rgb(119, 119, 119);
        font-size: 2.8vw;    
    
     }
    
    .team-student-container label{
        padding-left:7%; 
        padding-bottom: 1%;
        font-size: 2.5vw;
    }
    
    .team-student-card{
        width: 100%;
        background-color: rgba(255, 255, 255, 0.8);
        box-shadow:  0px 4px 8px rgba(0, 0, 0, 0.4);
        border-radius: 20px;
        padding-left:10%; 
        padding-top: 3%;
        text-align: start;
        color: black;
    
    }
    
    .team-student-name{
        font-size: 2.5vw;  
    }
    
    .team-student-tc{
        font-size: 2.5vw;
    }
    
    .team-update-btn {
        background-color: #8f9396;
        color: #ffffff;
        border-radius: 10px;
        padding-top: 1rem;
        padding-bottom: 1rem;
        width: 35vw;
        font-Family: "PoppinsMedium";
        font-size: 3vw;
        border: none;
    }
    
    .team-students-btns{
        width: 90%;
        background-color: rgba(255, 255, 255, 0.8);
        box-shadow:  inset 0px 4px 8px rgba(0, 0, 0, 0.4);
        border-radius: 30px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
    
    .team-student-active{
        width: 90%;
        border-radius: 30px;
        background-color: rgba(255, 255, 255, 0.9);
        box-shadow:   0px 4px 10px rgba(0, 0, 0, 0.8);
        border: none;
        padding-top: 2%;
        padding-bottom: 2%;
        font-size: 3vw;
    }
    
    .team-student-pasive{
        width: 90%;
        border-radius: 30px; 
        font-size: 3vw;
    }
    
    .team-update-desc{
        font-size: 2.7vw;
    }
    
    .team-update-form{
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    
    
    .mb-3 {
        margin-top: 2rem;
    }
    
    .team-update-label {
        display: block;
        width: 50vw;
        font-family: "PoppinsRegular";
        font-size: 2.7vw;
        text-align: start;
    }
    
    .team-update-input {
        border: none;
        border-bottom: 2px solid #000;
        border-radius: 0;
        background-color: transparent !important;
        width: 70vw;
        font-size: 3vw;

    }
}
/* Ekran genişliği 768px veya daha küçük olduğunda */
@media (max-width: 768px) {

    .my-team-bg {
  
        min-height: 100vh;   
    }
    
    .my-team-container {
        width: 90%;
        background-color: rgba(255, 255, 255, 0.5);
        border-radius: 50px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 4vh;
        box-shadow: inset 0px 4px 3px rgba(0, 0, 0, 0.4);
        flex-wrap: wrap;
        padding: 3% 4%;
        margin-top: 4vh;
        margin-bottom: 6vh;
    
    }
    
    .title-card{
        width: 80%;
        background-color: rgba(255, 255, 255, 0.8);
        box-shadow: inset 0px 4px 3px rgba(0, 0, 0, 0.4);
        border-radius: 50px;
        padding: 5% 8%;
    
    }
    
    .title-card span{
        font-size: 4.5vw;
    }
    
    .team-student-container{
        width: 90%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }
    
    .team-student-container span:nth-child(1){
       font-size:3.5vw;
    }
    
    .team-student-container span:nth-child(2){
        font-size: 3vw;     
    
     }
    
     .team-student-container span:nth-child(3){
        color: rgb(119, 119, 119);
        font-size: 3vw;    
    
     }
    
    .team-student-container label{
        padding-left:7%; 
        padding-bottom: 1%;
        font-size: 2.8vw;
    }
    
    .team-student-card{
        width: 100%;
        background-color: rgba(255, 255, 255, 0.8);
        box-shadow:  0px 4px 8px rgba(0, 0, 0, 0.4);
        border-radius: 10px;
        padding-left:10%; 
        padding-top: 3%;
        text-align: start;
        color: black;
    
    }
    
    .team-student-name{
        font-size: 3vw;  
    }
    
    .team-student-tc{
        font-size: 3vw;
    }
    
    .team-update-btn {
        background-color: #8f9396;
        color: #ffffff;
        border-radius: 10px;
        padding-top: 1rem;
        padding-bottom: 1rem;
        width: 35vw;
        font-Family: "PoppinsMedium";
        font-size: 3.2vw;
        border: none;
    }
    
    .team-students-btns{
        width: 100%;
        background-color: rgba(255, 255, 255, 0.8);
        box-shadow:  inset 0px 4px 8px rgba(0, 0, 0, 0.4);
        border-radius: 20px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
    
    .team-student-active{
        width: 90%;
        border-radius: 20px;
        background-color: rgba(255, 255, 255, 0.9);
        box-shadow:   0px 4px 10px rgba(0, 0, 0, 0.8);
        border: none;
        padding-top: 2%;
        padding-bottom: 2%;
        font-size: 3.2vw;
    }
    
    .team-student-pasive{
        width: 90%;
        border-radius: 20px; 
        font-size: 3.2vw;
    }
    
    .team-update-desc{
        font-size: 3vw;
    }
    
    .team-update-form{
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    
    
    .mb-3 {
        margin-top: 2rem;
    }
    
    .team-update-label {
        display: block;
        width: 80vw;
        font-family: "PoppinsRegular";
        font-size: 3vw;
        text-align: start;
    }
    
    .team-update-input {
        border: none;
        border-bottom: 2px solid #000;
        border-radius: 0;
        background-color: transparent !important;
        width: 80vw;
    }
 }
.signIn-main {
    display: flex;
    margin: 2rem 0;
    text-align: center;
    background-image: url('../../public/images/robo-rally-text.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    justify-content: center;
    width: 100vw;
}

.signIn-container {
    height: 55rem;
}

.signIn-section {
    align-items: center;
    display: flex;
    justify-content: center;
    margin-top: 1rem;
}

.signIn-card {
    border-radius: 70px;
    background-color: rgba(255, 255, 255, 0.4);
    margin-top: 1rem;
    align-items: center;
    box-shadow: -1px -2px 3px 3px rgba(220, 214, 214, 5);
    width: 100%;
    max-width: 35rem;
    height: 38rem;
}

.signIn-h2 {
    font-size: 2rem;
    font-weight: bold;
    margin-top: 2rem;
    margin-bottom: 1rem;
    font-family: PoppinsBold;
}

.signIn-form-label {
    display: block;
    text-align: start;
    margin-top: 1rem;
    width: 25rem;
    font-family: PoppinsRegular;
    font-size: 1rem;
}

.signIn-form-input {
    border: none;
    border-bottom: 2px solid #000;
    border-radius: 0;
    background-color: transparent !important;
    width: 25rem;
}

.signIn-hide-password-icon {
    margin-left: 11rem;
    transform: translateY(-50%);
    cursor: pointer;
    padding-bottom: 2rem;
}

.signIn-btn {
    background-color: #000;
    color: #ffffff;
    border-radius: 15px;
    padding-top: 0.7rem;
    padding-bottom: 0.7rem;
    margin-top: 2rem;
    font-family: PoppinsMedium;
    font-size: 1rem;
}

.signIn-sign-up-btn {
    background-color: #fefefe;
    width: 25rem;
    font-family: PoppinsMedium;
    font-size: 1rem;
    border-radius: 15px;
    padding-top: 0.7rem;
    padding-bottom: 0.7rem;
    margin-top: 1rem;
    margin-bottom: 5rem;
}

a {
    color: #000;
    font-family: PoppinsRegular;
    font-size: 1rem;
}

.signIn-p {
    font-family: PoppinsRegular !important;
    font-size: 1rem !important;
}

.signIn-signUp-section {
    margin-top: 3rem;

}

.signIn-underline {
    text-decoration: underline;
    margin-right: 0.3rem;
    margin-left: 0.3rem;
    cursor: pointer;
}

.signIn-text-info{
    font-size: 1rem!important;
}

/* phones */
@media (max-width: 768px) {

    .signIn-main {
        margin: 1rem 0;
        text-align: center;
        background-image: url('../../public/images/robo-rally-text.png');
        background-size: contain;
        background-position: top;
        background-repeat: no-repeat;
        height: 50rem;
        justify-content: center;
    }

    .signIn-section {
        align-items: center;
        display: flex;
        justify-content: center;
        margin-top: 2rem;
    }

    .signIn-card {
        border-radius: 70px;
        background-color: rgba(255, 255, 255, 0.4);
        margin-top: -1.5rem;
        align-items: center;
        box-shadow: -1px -2px 3px 3px rgba(220, 214, 214, 5);
        width: 95%;
        max-width: 35rem;
        height: 35rem;
    }

    .signIn-h2 {
        font-size: 1.5rem;
        font-weight: bold;
        margin-top: 0.5rem;
    }

    .signIn-form-label {
        display: block;
        text-align: start;
        margin-top: 1rem;
        width: 10rem;
        font-family: PoppinsRegular;
        font-size: 1rem;
    }

    .signIn-form-input {
        border: none;
        border-bottom: 2px solid #000;
        border-radius: 0;
        background-color: transparent;
        width: 100%;
    }

    .signIn-hide-password-icon {
        right: 3rem;
        transform: translateY(-50%);
        cursor: pointer;
        padding-bottom: 2rem;
    }

    .signIn-btn {
        background-color: #000;
        color: #ffffff;
        border-radius: 15px;
        padding-top: 0.7rem;
        padding-bottom: 0.7rem;
        margin-top: 2rem;
        font-family: PoppinsMedium;
        font-size: 1rem;
    }

    .signIn-sign-up-btn {
        background-color: #fefefe;
        border: 0.1rem solid black;
        color: #000;
        width: 20rem;
        font-family: PoppinsMedium;
        font-size: 1rem;
        border-radius: 15px;
        padding-top: 0.7rem;
        padding-bottom: 0.7rem;
        margin-top: 1rem;
        margin-bottom: 1rem;
    }

    a {
        color: #000;
        font-family: PoppinsRegular;
        font-size: 1rem;
    }

    .signIn-signIn-p {
        font-family: PoppinsRegular;
        font-size: 1rem;
    }

    .signIn-signUp-section {
        margin-top: 3rem;

    }

    .signIn-underline {
        text-decoration: underline;
        margin-right: 0.3rem;
        margin-left: 0.3rem;
        cursor: pointer;
        font-size: 0.7rem;
    }

}

/* tablets */
@media (min-width: 768px) and (max-width: 1024px) {
    
    .signIn-main {
        margin: 1rem 0;
        text-align: center;
        background-image: url('../../public/images/robo-rally-text.png');
        background-size: contain;
        background-position: top;
        background-repeat: no-repeat;
        height: 50rem;
        justify-content: center;
    }

}
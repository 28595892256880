.announcement-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 2%;
    margin-bottom: 2%;
    background-image: url('../../public/images/robo-rally-text.png');
    background-size: contain;
    /* Resmi kapsayacak şekilde ayarlar */
    background-position: center;
    /* Resmi ortalar */
    background-repeat: no-repeat;
    /* Resmin tekrarını engeller */
    width: 100vw;
    min-height: 130vh;

}

.first {
    border: 0.1rem solid black;
    border-radius: 4rem;
    background-color: #fff;
    display: inline-block;
    overflow: hidden;
    margin-bottom: 2rem;

}

.first-text {
    margin: 0.5rem;
    font-size: 1vw;
    font-family: "PoppinsLight";
    padding: 0vw 1.2vw;

}

.my-container-announcement {
    width: 80%;
    background-color: rgba(255, 255, 255, 0.3);
    /* Saydam arka plan */
    border-radius: 80px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    padding: 6%;
    box-shadow: inset 0px 2px 5px rgba(100, 100, 100, 0.9);
    /* Beyaz iç kenar gölgeliği */
}

.dropdown {
    position: relative;
    border: 0.1rem solid black;
    border-radius: 4rem;
    background-color: #fff;
    padding: 0.5rem 0.8rem;
    cursor: pointer;
    width: 18%;
    align-self: flex-end;
    /* Bu, drapdown'ı sağa yaslayacak */
    margin-top: -3vw;
    margin-bottom: 2vw;

}

.dropdown-content {
    position: absolute;
    top: 60px;
    /* Dropdown menünün açılacağı konum */
    right: 0;
    background-color: #f9f9f9;
    display: inline-block;
    min-width: 11vw;
    max-height: 13vw;
    overflow-y: auto;
    box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.5);
    border-radius: 10px;
    z-index: 1;

}

.dropdown-content p {
    font-family: "PoppinsLight";
    font-size: 1.04vw;
    padding: 6px 18px;
    cursor: pointer;
    border-bottom: 0.5px solid #ddd;
}

.dropdown-content p:hover {
    background-color: #f1f1f1;

}

.dropdown-text {
    margin: 0;
    font-family: "PoppinsLight";
    font-size: 1.1vw;
    display: flex;
    justify-content: space-between;
    /* Yazı sola, ikon sağa hizalanır */

}

.dropdown-icon {
    font-size: 1vw;

}

.announcementPart {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    /* Card'lar arasındaki boşluk */

}

.card {
    border-radius: 20px;
    flex: 1 1 20vw;
    /* Kartlar birbirine sığacak şekilde genişler */
    max-width: calc(33.33% - 1rem);
    /* 3 kart yan yana olması için genişlik ayarlaması */
    height: 38vh;
    padding: 0;
    margin-bottom: 1.5rem;
    /* Card'lar arasında dikey boşluk */

}

.card-img-top {
    height: 25vh;
    /* Kart yüksekliğinin 3'te 1'i */
    border-top-left-radius: 20px;
    /* Sol üst köşe */
    border-top-right-radius: 20px;
    /* Sağ üst köşe */
    object-fit: cover;

}


.card-body {
    height: 13vh;
    /* Kart yüksekliğinin 3'te 2'si */

}


.card-title {
    font-family: "DMSansBold";
    font-size: 0.8vw;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    /* Maksimum iki satır */
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    /* Fazla metin için ... */
    margin-left: 10px;
}

.card-text {
    font-family: "DMSansRegular";
    font-size: 0.72vw;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    /* Maksimum iki satır */
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    /* Fazla metin için ... */
    margin: 10px;
}

.card-content {
    display: flex;
    text-align: start;

}

.card-date {
    font-family: "DMSansBold";
    font-size: 1.45vw;

}

.card-date-month {
    color: blue;
    font-family: "DMSansBold";
    font-size: 0.7vw;
    margin-left: 3px;
}

.card-date-day {
    margin-top: -1rem;
    font-size: 1.2vw;
    font-weight: bold;
}

.pagination {
    display: flex;
    justify-content: center;
}

.paginationButton {
    width: 1.5vw;
    height: 3vh;
    background: #1bb2ef;
    border-radius: 50%;
    margin-left: 0.3vw;
    position: relative;
    border: none;

}

.paginationButton:hover,
.paginationButton:active,
.paginationButton:focus {
    background-color: #04399c;
    /* Üzerine gelindiğinde veya tıklandığında turuncu olur */
}

.paginationButton.active {
    /* Aktif buton turuncu olur */
    background-color: #0659f1;
    color: white; /* Yazı rengi beyaz */
    font-family: "PoppinsLight";

}


.announcement-modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(255, 255, 255, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
    width: 100vw;
    height: 100vh;
}

.announcement-modal-content {
    flex-direction: column;
    width: 60%;
    overflow-y: auto;
    position: relative;
    background-color: rgba(255, 255, 255, 0.9);
    border-radius: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2%;
    box-shadow: inset 0px 4px 5px rgba(100, 100, 100, 0.5);

}

.announcement-modal-top-image {
    width: 70%;
    height: 40vh;
    position: relative;
    object-fit: fill;
    border-radius: 40px;
    margin-bottom: 2vh;
    border: 0.5px #333 solid;
}



.announcement-modal-body {
    width: 80%;
    max-height: 40vh;
    overflow-y: auto;
    position: relative;
    background-color: rgba(255, 255, 255, 0.7);
    border-radius: 30px;
    padding: 3%;
    box-shadow: inset 0px 4px 5px rgba(100, 100, 100, 0.4);
    color: #333;

}

.announcement-modal-title {
    font-size: 1vw;
    font-family: "DMSansBold";
    text-align: start;
}

.announcement-modal-text {
    line-height: 1.7;
    font-size: 1vw;
    font-family: "DMSansRegular";
    text-align: start;
}

/* Scroll bar customization*/
.announcement-modal-body::-webkit-scrollbar {
    width: 20px;
}

/* Scrollbarın arka planı */
.announcement-modal-body::-webkit-scrollbar-track {
    background-color: rgba(255, 255, 255, 0.9);
    border-radius: 25px;

}

/* Scrollbarın tutamaç kısmı */
.announcement-modal-body::-webkit-scrollbar-thumb {
    background: linear-gradient(to top, darkblue, #1bb2ef);
    /* Tutamaç köşe yuvarlaklığı */
    border-radius: 30px;
    /* Tutamaç çevresi */
    border: 3px solid #f1f1f1;


}

/* Scrollbar üzerine gelindiğinde */
.announcement-modal-body::-webkit-scrollbar-thumb:hover {
    background: #035feb;
}


.announcement-close-button {
    cursor: pointer;
    background-color: #fff;
    color: #000;
    border-radius: 40px;
    width: 16vw;
    font-size: 1vw;
    font-family: "PoppinsLight";
    padding-top: 0.6rem;
    padding-bottom: 0.6rem;
    transition: filter 0.3s ease-in-out;
    /* Geçiş efekti */
    margin-top: 2%;

}

@media (min-width: 768px) and (max-width: 1024px) {

    .announcement-container {  
        background-position: top;
    }

    .my-container-announcement {
        width: 95%;
    }

    .announcementPart {
        flex-direction: column;
        align-items: center;
        /* Kartları ortalamak için ekledim */

    }

    .card {
        width: 100% !important;
        /* Kartlar tam genişlikte olur */
        max-width: 100% !important;
        /* Maksimum genişlik sınırlaması kaldırılır */
        margin-bottom: 1.5rem;
        /* Kartlar arasında dikey boşluk */
        border-radius: 20px;
    }

    .dropdown {
        align-self: center;
        width: 40%;
    }

    .dropdown-content {
        top: 35px;
        width: 98%;
        max-height: 25vw;
    }

    .dropdown-content p {
        font-size: 2vw;
        padding: 6px 10px;
    }

    .dropdown-text {
        font-size: 2.5vw;
    }

    .dropdown-icon {
        font-size: 3vw;
    }

    .first {
        width: 25vw;
    }

    .first-text {
        font-size: 2.8vw;
    }

    .card-title {
        font-size: 2.5vw !important;
    }

    .card-text {
        font-size: 2.1vw !important;
    }

    .card-date-month {
        font-size: 2.5vw !important;
    }

    .card-date-day {
        font-size: 4vw !important;
    }

    /* Modal arka planı (overlay) */
    .announcement-modal {
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        /* Tüm ekran genişliği */
        height: 100vh;
        /* Tüm ekran yüksekliği */
        background: rgba(255, 255, 255, 0.5);
        /* Yarı saydam arka plan */
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 1000;
        overflow: hidden;
     
        /* Ekran taşmasını engelle */
    }

    /* Modal içeriği */
    .announcement-modal-content {
        flex-direction: column;
        width: 95%;
        /* Genel genişliği sınırlı tut */
        max-width: 85vw;
        /* Ekranın %80'ini geçmesin */
        height: auto;
        max-height: 90vh;
        /* Yüksekliği ekranın %90'ını geçmesin */
        overflow-y: auto;
        /* İçerik taşarsa kaydırma */
        background-color: rgba(255, 255, 255, 0.9);
        /* Modal içerik rengi */
        border-radius: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 2%;
        box-shadow: inset 0px 4px 5px rgba(100, 100, 100, 0.5);
    }

    /* Resim */
    .announcement-modal-top-image {
        width: 100%;
        max-width: 80vw;
        /* Ekranın %80'ini geçmesin */
        height: auto;
        object-fit: cover;
        border-radius: 30px;
        margin-bottom: 2vh;
    }

    /* Modal içeriği */
    .announcement-modal-body {
        width: 100%;
        max-height: 40vh;
        overflow-y: auto;
        background-color: rgba(255, 255, 255, 0.7);
        border-radius: 30px;
        padding: 3%;
        box-shadow: inset 0px 4px 5px rgba(100, 100, 100, 0.4);
        color: #333;
    }

    /* Kapatma butonu */
    .announcement-close-button {
        cursor: pointer;
        background-color: #fff;
        color: #000;
        border-radius: 40px;
        width: 35vw;
        font-size: 2vw;
        padding: 0.5rem;
        margin-top: 2%;
        transition: filter 0.3s ease-in-out;
    }

    .announcement-modal-body::-webkit-scrollbar {
        width: 15px;
    }

    .announcement-modal-title {
        font-size: 2.5vw;
    }

    .announcement-modal-text {
        font-size: 2vw;
    }

    .paginationButton {
        width: 3vw;
        height: 2vh;
        background: #1bb2ef;
    }
}

/* Ekran genişliği 768px veya daha küçük olduğunda */
@media (max-width: 768px) {
    
    .announcement-container {  
        background-position: top;
    }


    .my-container-announcement {
        width: 95%;
    }

    .announcementPart {
        flex-direction: column;
    }

    .card {
        flex: 1 1 100%;
        /* Card'lar tam genişlikte olur */
        max-width: none;
        /* Maksimum genişlik sınırlaması kaldırılır */

    }

    .dropdown {
        align-self: center;
        width: 40%;

    }

    .dropdown-content {
        top: 35px;
        width: 98%;
        max-height: 25vw;
    }

    .dropdown-content p {
        font-size: 2.5vw;
        padding: 6px 15px;

    }

    .dropdown-text {
        font-size: 2.5vw;
    }

    .dropdown-icon {
        font-size: 3vw;
    }

    .first {
        width: 25vw;
    }

    .first-text {
        font-size: 3vw;
    }

    .card-title {
        font-size: 3.5vw;
    }

    .card-text {
        font-size: 3vw;
    }

    .card-date-month {
        font-size: 3vw;
    }

    .card-date-day {
        font-size: 4vw;
    }

    .announcement-modal-content {
        border-radius: 30px;
        width: 95%;

    }

    .announcement-modal-top-image {
        width: 80%;
        height: 25vh;
        position: relative;
        object-fit: fill;
        border-radius: 30px;
        margin-bottom: 2vh;
    }


    .announcement-modal-body {
        width: 85%;
        border-radius: 20px;
        max-height: 45vh;
    }

    .announcement-modal-body::-webkit-scrollbar {
        width: 10px;
    }

    .announcement-modal-title {
        font-size: 3.9vw;

    }

    .announcement-modal-text {
        font-size: 3.5vw;
    }

    .announcement-close-button {
        width: 35vw;
        font-size: 2.5vw;
    }

    .paginationButton {
        width: 5vw;
        height: 3.2vh;
        background: #1bb2ef;

    }
}
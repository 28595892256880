.archive-home-2023-container {
    display: flex;
    gap: 4.2%;
    flex-direction: column;
    align-items: center;
    background-image: url('../../public/images/archive/archivebackground.png');
    background-size: cover;
    /* Resmi kapsayacak şekilde ayarlar */
    background-repeat: no-repeat;
    /* Resmin tekrarını engeller */
    width: 100vw;
    height: 170vh;
}

.roboRally2023 {
    border: 1px solid black;
    border-radius: 40px;
    background-color: #fff;
    display: inline-block;
    overflow: hidden;
    padding-left: 1rem;
    padding-right: 1rem;
    height: 40px;

}

.roboRally2023-text {
    margin: 0.5rem;
    font-size: 1vw;
    font-family: "PoppinsLight";
}

.roborally-2023-description-div {
    width: 75%;
    background-color: rgba(0, 0, 0, 0.7);
    border-radius: 80px;
    display: flex;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.5);
    justify-content: center;
    flex-direction: column;
    flex-wrap: wrap;
    padding: 2%;
    padding-bottom: 4%;
    margin-bottom: 85px;
}

.roborally-2023-first-text {
    color: white;
    font-size: 2.5vw;
    position: relative;
    /* Yazıyı resmin üstünde tutar */
    z-index: 0;
    font-family: "LufgaMedium";
}

.roborally-2023-school-text {
    font-size: 2.5vw;
    background: linear-gradient(to right, yellow, darkorange);
    /* Gradyan tanımı */
    -webkit-background-clip: text;
    /* Gradyanı sadece metne uygular */
    -webkit-text-fill-color: transparent;
    /* Metni saydam yaparak gradyanın görünmesini sağlar */
    position: relative;
    /* Yazıyı diğer içeriklerle uyumlu hale getirir */
    z-index: 0;
    font-family: "LufgaMedium";
}

.roborally-2023-description-text {
    color: white;
    font-size: 1.08vw;
    position: relative;
    /* Yazıyı resmin altına yerleştirmek için */
    z-index: 0;
    margin-top: 1.5vh;
    /* Yazıyı biraz aşağıya kaydırır */
    font-family: "LufgaRegular";
    text-indent: 2em;
    /* Cümle başında bir tab boşluk */
    text-align: center;
    line-height: 3.5vh;
}

.vector1-image-2023 {
    position: absolute;
    right: 7.5vw;
    top: -1vh;
    transform: translateX(-50%);
    width: 2vw;
    /* Resmin genişliği */
    height: auto;
    /* Yüksekliği orantılı olarak ayarlar */
    z-index: 1;
    /* Resmin üstte görünmesini sağlar */
}

.vector-image-2023 {
    position: absolute;
    transform: translateX(-50%);
    right: -3vw;
    top: 5vh;
    width: 1.5vw;
    /* Resmin genişliği */
    height: auto;
    /* Yüksekliği orantılı olarak ayarlar */
    z-index: 1;
    /* Resmin üstte görünmesini sağlar */
}

.vector2-image-2023 {
    position: absolute;
    left: 2vw;
    bottom: -6vh;
    transform: translateY(-50%);
    /* Resmi ortalamak için */
    width: 1.5vw;
    /* Resmin genişliği */
    height: auto;
    /* Yüksekliği orantılı olarak ayarlar */
    z-index: 1;
    /* Resmin üstte görünmesini sağlar */
}

.menu-buton-div-2023 {
    width: 100vw;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: 3vw
}

.menu-buton-2023 {
    background-color: #fff;
    color: #000;
    border-radius: 40px;
    padding-top: 0.7rem;
    padding-bottom: 0.7rem;
    width: 17rem;
    font-size: 1vw;
    font-family: "PoppinsRegular";

}

@media (min-width: 768px) and (max-width: 1024px) {

    .archive-home-2023-container {   
        height: 110vh;
    }


    .roboRally2023 {
        padding-left: 1.5rem;
        padding-right: 1.5rem;
        height: 45px;
    
    }

    .archive-home-2023-container {
        gap: 6%;
        background-size: contain;
        padding: 10px;
        min-height: 50vh;
    }

    .roboRally2023-text {
        font-size: 3vw;
    }


    .roborally-2023-description-div {
        width: 97%;
        border-radius: 60px;
        padding: 3%;
        padding-top: 10%;
        padding-bottom: 10%;
        margin-bottom: 45px;
    }

    .roborally-2023-first-text {
        font-size: 4vw;

    }

    .roborally-2023-school-text {
        font-size: 4vw;
      
    }

    .roborally-2023-description-text {
        color: white;
        font-size: 3.2vw;      
        text-align: center;
        line-height: 2.8vh;
    }

    .vector1-image-2023 {
        right: -1vw;
        width: 3vw;
     
    }

    .vector-image-2023 {
        right: -6vw;
        top: 3vh;
        width: 3vw;
      
    }

    .vector2-image-2023 {
        left: 5vw;
        bottom: -4vh;
        width: 3.5vw;
    }
  

    .menu-buton-2023 {
        width: 20rem;
        font-size: 3vw;
    }

}

/* Ekran genişliği 768 pikselden küçükse */
@media (max-width: 768px) {

    .archive-home-2023-container {
        gap: 2%;
        /* Daha küçük boşluk */
        background-size: contain;
        /* Mobil ekran için uygun ayarla */
        padding: 10px;
        /* Kenar boşluklarını daralt */
        min-height: 50vh;
    }

    .roboRally2023-text {
        font-size: 3vw;
    }


    .roborally-2023-description-div {
        width: 95%;
        border-radius: 50px;
        padding: 3%;
        padding-top: 10%;
        padding-bottom: 10%;
        margin-bottom: 45px;
    }

    .roborally-2023-first-text {
        font-size: 4vw;

    }

    .roborally-2023-school-text {
        font-size: 4vw;
      
    }

    .roborally-2023-description-text {
        color: white;
        font-size: 3.2vw;      
        text-align: center;
        line-height: 2.8vh;
    }

    .vector1-image-2023 {
        right: -1vw;
        width: 3vw;
     
    }

    .vector-image-2023 {
        right: -6vw;
        top: 3vh;
        width: 3vw;
      
    }

    .vector2-image-2023 {
        left: 5vw;
        bottom: -4vh;
        width: 3.5vw;
    }
  

    .menu-buton-2023 {
        width: 17rem;
        font-size: 3vw;
    }

}
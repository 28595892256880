.start-exam-executive-main {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-bottom: 2%;
  background-image: url('../../public/images/robo-rally-text.png');
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  min-height: 100vh;
}

.container {
  max-width: 60rem;
}

.rules {
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  text-align: left;
}

.rules-approve-text {  
  font-family: 'PoppinsBold';
  font-size: 1rem;
}

.rules ol {
  margin-left: -1rem;
}

.rules ul {
  list-style-type: disc;
  margin-left: 20px;
  margin-left: 0rem;

  font-family: 'PoppinsLight';
  font-size: 1.1rem;
}

p {
  font-family: 'PoppinsLight';
  font-size: 1.1rem;
}

.start-exam-header {
  font-family: 'PoppinsMedium';
  font-size: 2rem;
}

.start-exam-header-icon {
  margin-right: 0.7rem;
}

.start-exam-list-header {
  font-family: 'PoppinsMedium';
  font-size: 1.2rem;
}

.form-check {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 3rem;
}

.rules-check-input {
  display: none;
  /* Standart checkbox'ı gizle */
}

.rules-check-input+.form-check-label {
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 35px;
  /* Checkbox'ın alanını koruyacak */
  cursor: pointer;
}

.rules-check-input+.form-check-label::before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 30px;
  height: 20px;
  border-radius: 40%;
  background-color: #fafafa;
  box-shadow: 1px -2px 1px 1px rgba(196, 196, 196, 5);
}

.rules-check-input:checked+.form-check-label::before {
  background-color: #172ad1;
  /* Seçili olduğunda arka plan rengi */
  border-color: #172ad1;
  /* Seçili olduğunda kenar rengi */
}

.rules-check-input:checked+.form-check-label::after {
  content: "";
  position: absolute;
  left: 7px;
  top: 50%;
  transform: translateY(-50%);
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: white;
}

.form-check-label-text {
 font-family: 'PoppinsRegular';
 font-size: 1.25rem;
}

.start-exam-btn-primary {
  font-family: 'PoppinsMedium';
  font-size: 2rem;
  background-color: #ffffff;
  border-color: #ffffff;
  color: black;
  border-radius: 45px;
  padding: 1rem 5rem;
  box-shadow: 1px -3px 2px 1px rgba(222, 222, 222, 5);
  margin-top: 1rem;
  margin-bottom: 5rem;
}

.start-exam-btn-primary:hover {
  background-color: #f7f7f7;
  border-color: #ffffff;
  color: black;
}

.warning-text {
  color: red;
  font-weight: bold;
}

.poppins-regular-text {
  font-family: 'PoppinsRegular';
}

.poppins-mediun-text {
  font-family: 'PoppinsMedium';
}

.poppins-light-text {
  font-family: 'PoppinsLight';
}

.poppins-bold-text {
  font-family: 'PoppinsBold';
}
.team-register-bg {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    background-image: url('../../public/images/robo-rally-text.png');
    background-size: contain;
    /* Resmi kapsayacak şekilde ayarlar */
    background-position: center;
    /* Resmi ortalar */
    background-repeat: no-repeat;
    /* Resmin tekrarını engeller */
    width: 100vw;
    min-height: 140vh;

}

.team-register-container {
    width: 80%;
    background-color: rgba(255, 255, 255, 0.13);
    border-radius: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 5vh;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.5);
    flex-wrap: wrap;
    padding: 3% 8%;
    margin-top: 7vh;

}

.team-register-desc {
    align-self: flex-start;
}

.team-register-desc p:nth-child(1) {
    color: black;
    font-size: 1.8vw;
    font-weight: bold;
    font-family: "PoppinsMedium";
    text-align: left;
}

.team-register-desc p:nth-child(2) {
    color: rgb(107, 105, 105);
    font-size: 1vw;
    font-family: "PoppinsLight"
}


.team-register-form {
    width: 70%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.mb-3 {
    margin-top: 3.5rem;
}

.team-form-label {
    display: block;
    margin-top: 2.5rem;
    width: 30vw;
    font-family: "PoppinsRegular";
    font-size: 1vw;
    text-align: start;
}

.team-form-input {
    border: none;
    border-bottom: 2px solid #000;
    border-radius: 0;
    background-color: transparent !important;
    width: 30vw;
}

.team-register-btn {
    background-color: #8f9396;
    color: #ffffff;
    border-radius: 15px;
    padding-top: 0.9rem;
    padding-bottom: 0.9rem;
    margin-top: 2rem;
    margin-bottom: 1rem;
    width: 30vw;
    font-Family: "PoppinsMedium";
    font-size: 1.04vw;
    border: none;

}

@media (min-width: 768px) and (max-width: 1024px) {
    .team-register-bg {
        min-height: 100vh;
    }

    .team-register-container {
        width: 95%;
        border-radius: 20px;
        gap: 3vh;
        padding: 4% 8%;
        margin-top: 5vh;

    }


    .team-register-desc p:nth-child(1) {
        color: black;
        font-size: 4vw;
    }

    .team-register-desc p:nth-child(2) {
        font-size: 2.5vw;
        text-align: left;
    }

    .mb-3 {
        margin-top: 4rem;
    }

    .team-form-label {
        display: block;
        margin-top: 3rem;
        width: 60vw;
        font-size: 3vw;
        text-align: start;
    }

    .team-form-input {
        width: 80vw;
    }

    .team-register-btn {

        width: 80vw;
        font-size: 3vw;

    }
}

/* Ekran genişliği 768px veya daha küçük olduğunda */
@media (max-width: 768px) {

    .team-register-bg {
        min-height: 120vh;

    }

    .team-register-container {
        width: 90%;
        border-radius: 20px;
        gap: 3vh;
        padding: 4% 8%;
        margin-top: 5vh;

    }


    .team-register-desc p:nth-child(1) {
        color: black;
        font-size: 5vw;
        font-weight: bold;
        font-family: "PoppinsLight";
        text-align: left;
    }

    .team-register-desc p:nth-child(2) {
        font-size: 3vw;
        text-align: left;
    }

    .mb-3 {
        margin-top: 2.5rem;
    }

    .team-form-label {
        display: block;
        margin-top: 2.5rem;
        width: 50vw;
        font-size: 3vw;
        text-align: start;
    }

    .team-form-input {
        width: 80vw;
    }

    .team-register-btn {

        width: 80vw;
        font-size: 3vw;

    }
}
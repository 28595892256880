.accommodation-bg-img {
    display: flex;
    margin: 2rem 0;
    background-image: url('../../public/images/robo-rally-text.png');
    background-size: contain;
    background-position: top;
    background-repeat: no-repeat;
    width: 100vw;
    justify-content: center;
}

.accommodation-container {
    min-height: 60rem;
}

.accommodation-card {
    border-radius: 75px;
    background-color: rgba(255, 255, 255, 0.4);
    margin-top: 1rem;
    text-align: left;
    box-shadow: -1px -2px 3px 3px rgba(220, 214, 214, 5);
    width: 100%;
    height: auto;
}

.accommodation-advisor-card {
    border-radius: 25px;
    background-color: white;
    margin-top: 1rem;
    text-align: left;
    box-shadow: 0px 2px 1px 3px rgba(220, 214, 214, 5);
    width: 100%;
    height: auto;
    padding-left: 4rem;
    padding-top: 0.5rem;
    padding-bottom: 0.1rem;
}

.accommodation-student-card {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #ddd;
    margin-bottom: 10px;
    box-shadow: 0px 2px 1px 3px rgba(220, 214, 214, 5);
    border-radius: 25px;
    padding-left: 4rem;
    padding-top: 0.5rem;
}

.accommodation-student-card-delete {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-right: 2rem;
}

.accommodation-student-card-delete-img {
    cursor: pointer;
    width: 2rem;
    height: 2rem;
}

.accommodation-form {
    padding: 2rem;
}

.accommodation-custom-input {
    border: none;
    border-bottom: 2px solid #000;
    border-radius: 0;
    background-color: transparent !important;
    font-size: 1rem;
    font-family: "PoppinsRegular";
    width: 35rem;
}

.accommodation-form-label {
    display: block;
    margin-top: 2rem;
    width: 35rem;
    font-family: PoppinsRegular;
    font-size: 1rem;
}

.accommodation-form-input {
    border: none;
    border-bottom: 2px solid #000;
    border-radius: 0;
    background-color: transparent !important;
    width: 35rem;
}

.accomodation-warning-text-header {
    font-size: 1.5rem;
}

.accomodation-warning-text {
    width: 40rem;
    white-space: normal;
    word-wrap: break-word;
    text-align: justify
}

.send-accommodation-btn {
    background-color: #8f9396;
    border: 0;
    color: #ffffff;
    border-radius: 15px;
    padding-top: 0.9rem;
    padding-bottom: 0.9rem;
    margin-top: 1.5rem;
    font-family: PoppinsMedium;
    font-size: 1rem;
}

.add-student-btn-container {
    display: flex;
    justify-content: flex-end;
}

.add-student-btn {
    background-color: #8f9396;
    border: 0;
    color: #ffffff;
    border-radius: 15px;
    padding-top: 0.9rem;
    padding-bottom: 0.9rem;
    margin-top: 2rem;
    font-family: PoppinsMedium;
    font-size: 1.2rem;
}

.accommodation-p {
    font-family: PoppinsRegular;
    font-size: 2rem;
}

.accommodation-advisor-card-p {
    font-size: 1.25rem;
}

.accommodation-advisor-card-name-p {
    margin-bottom: 0;
}

.poppins-regular-text {
    font-family: 'PoppinsRegular';
}

.poppins-mediun-text {
    font-family: 'PoppinsMedium';
}

.poppins-light-text {
    font-family: 'PoppinsLight';
}

.poppins-bold-text {
    font-family: 'PoppinsBold';
}


/* phones */
@media (max-width: 768px) {

    .accommodation-bg-img {
        margin: 1rem 0;
        text-align: center;
        background-image: url('../../public/images/robo-rally-text.png');
        background-size: contain;
        background-position: top;
        background-repeat: no-repeat;
        height: auto;
    }

    .accommodation-container{
        width: 95%;
    }

    .accommodation-card {
        border-radius: 70px;
        background-color: rgba(255, 255, 255, 0.4);
        align-items: center;
        box-shadow: -1px -2px 3px 3px rgba(220, 214, 214, 5);
        width: 100%;
        height: auto;
    }

    .accommodation-advisor-card {
        border-radius: 25px;
        background-color: white;
        margin-top: 1rem;
        text-align: left;
        box-shadow: 0px 2px 1px 3px rgba(220, 214, 214, 5);
        width: 100%;
        height: auto;
        padding-left: 4rem;
        padding-top: 0.5rem;
        padding-bottom: 0.1rem;
    }

    .accommodation-student-card {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border: 1px solid #ddd;
        margin-bottom: 10px;
        box-shadow: 0px 2px 1px 3px rgba(220, 214, 214, 5);
        border-radius: 25px;
        padding-left: 4rem;
        padding-top: 0.5rem;
    }

    .accommodation-student-card-delete {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin-right: 2rem;
    }

    .accommodation-student-card-delete-img {
        cursor: pointer;
        width: 2rem;
        height: 2rem;
    }

    .accommodation-form {
        padding: 1rem;
    }

    .accommodation-custom-input {
        border: none;
        border-bottom: 2px solid #000;
        border-radius: 0;
        background-color: transparent !important;
        font-size: 1rem;
        font-family: "PoppinsRegular";
        width: 100%;
    }

    .accommodation-form-label {
        display: block;
        text-align: start;
        margin-top: 1rem;
        width: 100%;
        font-family: PoppinsRegular;
        font-size: 1rem;
    }

    .accommodation-form-input {
        border: none;
        border-bottom: 2px solid #000;
        border-radius: 0;
        background-color: transparent;
        width: 100%;
    }

    .send-accommodation-btn {
        background-color: #8f9396;
        color: #ffffff;
        border-radius: 15px;
        padding-top: 0.7rem;
        padding-bottom: 0.7rem;
        margin-top: 2rem;
        font-family: PoppinsMedium;
        font-size: 1.2rem;
    }

    .add-student-btn-container {
        display: flex;
        justify-content: flex-end;
    }

    .add-student-btn {
        background-color: #8f9396;
        border: 0;
        color: #ffffff;
        border-radius: 15px;
        padding-top: 0.9rem;
        padding-bottom: 0.9rem;
        margin-top: 2rem;
        font-family: PoppinsMedium;
        font-size: 1rem;
    }

    p {
        font-family: PoppinsRegular;
        font-size: 1rem;
    }  

}

/* tablets */
@media (min-width: 768px) and (max-width: 1024px) {

    .accommodation-custom-input {
        border: none;
        border-bottom: 2px solid #000;
        border-radius: 0;
        background-color: transparent !important;
        font-size: 1rem;
        font-family: "PoppinsRegular";
        width: 35rem;
    }
}
.dark-tb-main {
    display: flex;
    margin: 2rem 0;
    text-align: center;
    background-image: url('../../public/images/robo-rally-text.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 100vw;
    min-height: 100vh;
    padding-right: 5rem;
    padding-left: 5rem;
    justify-content: center;
}

.dark-tb-container {
    width: 100%;
}

.tb-header-text {
    font-family: 'PoppinsBold';
    font-size: 1.5rem;
}

.dark-tb-body-text {
    font-size: 1.25rem;
    font-family: 'PoppinsRegular';
}

thead .dark-bg-color {
    background-color: #414244;
    padding: 1rem;
}

tbody .dark-bg-color {
    background-color: #414244;
    padding: 1rem;
}

th,
td {
    padding: 1rem;
}

.dark-tb-update-btn {
    margin-right: 1rem;
}

.search-container {
    display: flex;
    justify-content: space-between;
    gap: 10px;
}

.search-input {
    width: 20rem;
}

.institution-pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #414244;
    padding: 1rem;
    margin-top: -10px;
}

.institution-pagination button {
    margin: 0 12px;
    padding: 5px 20px;
    font-size: 1.1rem;
    cursor: pointer;
    border-radius: 8px;
    background-color: rgb(238, 241, 243);
}

.icons-container {
    display: flex;
    flex-basis: 95px;
    justify-content: space-between;
}

/* phones */
@media (max-width: 768px) {

    .dark-tb-main {
        margin: 1rem 0;
        text-align: center;
        background-image: url('../../public/images/robo-rally-text.png');
        background-size: contain;
        background-position: top;
        background-repeat: no-repeat;
        height: 100%;
        width: 100vw;
        padding-right: 0rem;
        padding-left: 0rem;
        justify-content: center;
    }

    .dark-tb-container {
        width: 95%;
    }

    .dark-tb-add-btn {
        margin-top: 0.5rem;
    }

    .dark-tb-update-btn {
        margin-right: 0;
    }

    .dark-tb-delete-btn {
        margin-top: 0.5rem;
    }

    thead .dark-bg-color {
        font-size: 0.5rem;
    }

    tbody .dark-bg-color {
        font-size: 0.5rem;
    }

    th,
    td {
        font-size: 0.5rem;
    }

    .dark-tb-body-text {
        font-size: 0.5rem;
    }

    .search-input {
        width: 15rem;
    }

    .institution-pagination button {
        margin: 0 10px;
        padding: 5px 15px;
        font-size: 0.9rem;
    }
}

/* tablets */
@media (min-width: 768px) and (max-width: 1024px) {

    .dark-tb-main {
        margin: 1rem 0;
        text-align: center;
        background-image: url('../../public/images/robo-rally-text.png');
        background-size: contain;
        background-position: top;
        background-repeat: no-repeat;
        height: 100%;
        width: 100vw;
        padding-right: 0rem;
        padding-left: 0rem;
        justify-content: center;
    }

    .dark-tb-container {
        width: 95%;
    }

}
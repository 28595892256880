* {
    margin: 0;
    padding: 0;
}

.home-body {
    font-family: Arial, sans-serif;
    background-color: #f5f5f5;
}

.home-app {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    
}

.home-main {
    margin: 2rem 0;
    margin-top: 7rem;
    text-align: center;
    background-image: url('../../public/images/robo-rally-text.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
    height: 100vh;
}

.home-main-content h1 {
    font-family: 'UrbanistSemiBold';
    font-size: 4.9vw;
    color: #333;
    margin-bottom: 3rem;
}

.home-main-content p {
    font-family: 'PoppinsRegular';
    font-size: 1.04vw;
}

.home-main-content-first {
    box-shadow: 1px -3px 2px 1px rgba(196, 196, 196, 5);
    border-radius: 2.5rem;
    padding-left: 1rem;
    padding-right: 1rem;
    background-color: #fff;
    display: inline-block;
    overflow: hidden;
    margin-bottom: 3rem;
    margin-top: -2rem;
}

.home-main-content-first-img {
    margin-top: -3rem;
}

.home-highlight {
    background: linear-gradient(to right, darkblue, #1bb2ef);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.home-track-image {
    margin-top: -25rem;
    margin-bottom: 1rem;
    text-align: center;
    background-image: url('../../public/images/homepage/track.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
    height: 172vh;
}

.home-buttons {
    margin-top: 5rem;
    box-shadow: 1px -3px 2px 1px rgba(196, 196, 196, 5);
    background-color: white;
    border-radius: 50px;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    display: inline-block;
    overflow: hidden;
    height: 6.5rem;
    align-items: center;
}

.home-btn {
    padding: 1rem 1.5rem;
    margin: 0 1rem;
    border: none;
    cursor: pointer;
    margin-top: 1rem;
    border-radius: 40px;
    transition: background-color 0.3s, box-shadow 0.3s;
    z-index: 10;
    position: relative;
}

.home-register {
    background-color: #ff9900;
    color: #000;
    padding: 1rem;
    font-family: 'PoppinsMedium';
    font-size: 1.1vw;
}

.home-register-icon {
    margin-left: 1rem
}

.home-login {
    background-color: #333;
    color: #000;
    padding: 1rem;
    font-family: 'PoppinsLight';
    font-size: 1.1vw;
}

.city-scroller-header {
    color: #b3b3b3;
    z-index: 15;
    transform: skewY(3deg); /* Eğiklik */
    text-align: left;
    font-family: 'PoppinsSemiBold';
    font-size: 2.1rem;
    position: relative;
    padding-left: 0;
    width: 80%; 
    margin-bottom: -2rem;
    margin-top: 2rem;
    overflow-x: hidden;
}

.city-scroller-wrapper {
    position: relative;
    width: 100%;
    height: 40vh;
    display: flex;
    flex-direction: column;
    margin-bottom: 3rem;
    justify-content: flex-start;
    overflow-x: hidden;
}

.city-scroller-reverse {
    width: 100%;
    overflow-x: hidden;
    white-space: nowrap;
    padding: 1.5rem 0;
    position: absolute;
    top: 3.5rem; 
    z-index: 5; 
    background-image: url('../../public/images/homepage/back.png');
    background-size: 200% 200vh;
    background-repeat: no-repeat;
    background-position: center;
    transform: skewY(3deg); 
}

.city-list-reverse {
    display: inline-block;
    padding-left: 100%;
    animation: scroll 25s linear infinite;
}

.city-scroller {
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    padding: 1.5rem 0;
    position: absolute;
    bottom: 5rem; 
    z-index: 10; 
    background-image: url('../../public/images/homepage/front.png');
    background-size: 200% 100vh;
    background-repeat: no-repeat;
    background-position: center;
    transform: skewY(-3deg);
}

.city-list {
    display: inline-block;
    padding-left: 100%;
    animation: scroll-reverse 20s linear infinite;
}

.city-item {
    display: inline-block;
    margin-right: 40px;
    color: white;
    font-weight: bold;
    font-size: 2.1rem;
}

.city-item-img {
   margin-left: 2rem;
}

@keyframes scroll {
    0% {
        transform: translateX(0);
    }

    100% {
        transform: translateX(-100%);
    }
}

@keyframes scroll-reverse {
    0% {
        transform: translateX(0);
    }

    100% {
        transform: translateX(-100%);
    }
}

.home-announcements-header {
    border-radius: 25px;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-left: 2rem;
    padding-right: 2rem;
    background-color: #fff;
    display: inline-block;
    overflow: hidden;
    box-shadow: 1px -3px 2px 1px rgba(222, 222, 222, 5);
    font-family: 'PoppinsRegular';
    font-size: 1.68rem;
    cursor: pointer;
}

.home-announcements {
    width: 95%;
    display: flex;
    justify-content: center;
    margin: 1rem 0;
    text-align: left;
    background-color: #ebebeb;
    border-radius: 95px;
    margin-bottom: 3rem;
    box-shadow: 0px -2px 1px 2px rgba(213, 213, 213, 5);
}

.home-announcement-cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    margin: 2rem 3rem;
    width: 80%;
    cursor: pointer;
}

.home-card {
    margin-top: 0.5rem;
    border-radius: 20px;
    width: 22vw;
    height: 100%;
    background-color: white;
}

.home-card-img-top {
    height: 15rem;
    width: 100%;
    border-radius: 20px 20px 0 0;
    margin-bottom: 1rem;
}

.home-card-body {
    height: 100%;
}

.home-card-title {
    font-family: 'DMSansBold';
    font-size: 1rem;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    /* Maksimum iki satır */
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.home-card-text {
    font-family: 'DMSansRegular';
    font-size: 0.875rem;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.home-card-content {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.home-card-date {
    font-family: 'DMSansBold';
    font-size: 1.45vw;
    padding-left: 1rem;
}

.home-card-date-month {
    color: blue;
    font-family: 'DMSansBold';
    font-size: 0.7rem;
    margin-left: 0.1rem;
}

.home-card-date-day {
    margin-top: -1rem;
    font-size: 1.75rem;
    font-weight: bold;
}

.home-card-text-content {
    width: 90%;
    font-size: 0.875rem;
    margin-left: 1rem;
}

/* phones */
@media (max-width: 1024px) {

    .home-main {
        margin: 1rem 0;
        text-align: center;
        background-image: url('../../public/images/robo-rally-text.png');
        background-size: cover;
        /* Resmi kapsayacak şekilde ayarlar */
        background-position: center;
        /* Resmi ortalar */
        background-repeat: no-repeat;
        /* Resmin tekrarını engeller */
        height: 25vh;
        margin-top: 2rem;
    }

    .home-track-image {
        margin-top: 2rem;
        margin-bottom: -5rem;
        text-align: center;
        background-image: url('../../public/images/homepage/track.png');
        background-size: cover;
        /* Resmi kapsayacak şekilde ayarlar */
        background-position: center;
        /* Resmi ortalar */
        background-repeat: no-repeat;
        /* Resmin tekrarını engeller */
        height: 40vh;
    }

    .home-main-content h1 {
        font-family: 'UrbanistSemiBold';
        font-size: 7vw;
        color: #333;
        margin-bottom: 1rem;
    }

    .home-main-content p {
        font-family: 'PoppinsRegular';
        font-size: 2.8vw;
    }

    .home-main-content-first {
        box-shadow: 1px -3px 2px 0px rgba(196, 196, 196, 5);
        border-radius: 2rem;
        padding-left: 1rem;
        padding-right: 1rem;
        background-color: #fff;
        display: inline-block;
        overflow: hidden;
        margin-bottom: 1rem;
        margin-top: 0rem;
    }

    .home-main-content-first-img {
        margin-top: -1rem;
        height: 7vh;
    }

    .home-highlight {
        color: #ff9900;
        font-size: 7vw;
    }

    .home-buttons {
        margin-top: 0.5rem;
        box-shadow: 1px -3px 2px 0px rgba(196, 196, 196, 5);
        border-radius: 50px;
        padding-left: 0.5rem;
        padding-right: 0.5rem;
        display: inline-block;
        overflow: hidden;
        height: 3.5rem;
        align-items: center;
        z-index: 10;
    }

    .home-btn {
        padding: 0.75rem 1.5rem;
        margin: 0 0.5rem;
        border: none;
        cursor: pointer;
        margin-top: 0.4rem;
        border-radius: 40px;
    }

    .home-register {
        background-color: #ff9900;
        color: #000;
        font-family: 'PoppinsMedium';
        font-size: 3vw;
    }

    .home-register-icon {
        margin-left: 1rem
    }

    .home-login {
        background-color: #333;
        color: #000;
        font-family: 'PoppinsLight';
        font-size: 3vw;
    }

    .city-scroller-header {
        color: #b3b3b3;
        z-index: 15;
        transform: skewY(3deg); /* Eğiklik */
        text-align: left;
        font-family: 'PoppinsSemiBold';
        font-size: 1.25rem;
        position: relative;
        padding-left: 0;
        margin-left: 2rem;
        width: 100%; 
        margin-bottom: -2rem;
        margin-top: 7rem;
    }
    
    .city-scroller-wrapper {
        position: relative;
        width: 100%;
        height: 27vh;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        margin-bottom: -6rem;
    }
    
    .city-scroller-reverse {
        width: 100%;
        overflow: hidden;
        white-space: nowrap;
        padding: 5px 0;
        position: absolute;
        top: 5vh; 
        z-index: 5; 
        background-image: url('../../public/images/homepage/back.png');
        background-size: 200% 50vh;
        background-repeat: no-repeat;
        background-position: center;
        transform: skewY(3deg); 
    }
    
    .city-list-reverse {
        display: inline-block;
        padding-left: 100%;
        animation: scroll 35s linear infinite;
    }
    
    .city-scroller {
        width: 100%;
        overflow: hidden;
        white-space: nowrap;
        padding: 5px 0;
        position: absolute;
        top: 10vh;
        z-index: 10; 
        background-image: url('../../public/images/homepage/front.png');
        background-size: 200% 50vh;
        background-repeat: no-repeat;
        background-position: center;
        transform: skewY(-3deg); 
    }
    
    .city-list {
        display: inline-block;
        padding-left: 100%;
        animation: scroll-reverse 20s linear infinite;
    }
    
    .city-item {
        display: inline-block;
        margin-right: 40px;
        color: white;
        font-weight: bold;
        font-size: 1rem;
    }
    
    .city-item-img {
       margin-left: 1.25rem;
       height: 2rem;
    }
    
    @keyframes scroll {
        0% {
            transform: translateX(0);
        }
    
        100% {
            transform: translateX(-100%);
        }
    }
    
    @keyframes scroll-reverse {
        0% {
            transform: translateX(0);
        }
    
        100% {
            transform: translateX(-100%);
        }
    }

    .home-announcements-header {
        border-radius: 25px;
        padding-bottom: 0.5rem;
        padding-left: 2rem;
        padding-right: 2rem;
        background-color: #fff;
        display: inline-block;
        overflow: hidden;
        box-shadow: 1px -3px 2px 1px rgba(222, 222, 222, 5);
        font-family: 'PoppinsRegular';
        font-size: 3vw;
        margin-top: 5rem;
    }

    .home-announcements {
        width: 95%;
        display: flex;
        justify-content: center;
        margin: 1rem 0;
        text-align: left;
        background-color: #ebebeb;
        border-radius: 75px;
        margin-bottom: 3rem;
        box-shadow: 1px -3px 2px 1px rgba(222, 222, 222, 5);
    }

    .home-announcement-cards {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        margin: 2rem 0;
        width: 80%;
    }

    .home-card {
        margin-top: 0.5rem;
        border-radius: 20px;
        width: 18rem;
        height: 21rem;
        margin-bottom: 1rem;
    }

    .home-card-img-top {
        height: 15rem;
    }

    .home-card-body {
        height: 11rem;
    }

    .home-card-title {
        font-family: 'DMSansBold';
        font-size: 3vw;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        /* Maksimum iki satır */
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        /* Fazla metin için ... */
    }

    .home-card-text {
        font-family: 'DMSansRegular';
        font-size: 2.5vw;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        /* Maksimum iki satır */
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        /* Fazla metin için ... */
    }

    .home-card-content {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
    }

    .home-card-date {
        font-family: 'DMSansBold';
        font-size: 2vw;
    }

    .home-card-date-month {
        color: blue;
        font-family: 'DMSansBold';
        font-size: 2.5vw;
        margin-left: 0.3rem;
    }

    .home-card-date-day {
        margin-top: -1rem;
        font-size: 25px;
        font-weight: bold;
    }

    .home-card-text-content {
        width: 85%;
        font-size: 1rem;
    }

}

/* tablets */
@media (min-width: 768px) and (max-width: 1024px) {

    .home-track-image {
        margin-top: 3rem;
        margin-bottom: -5rem;
        text-align: center;
        background-image: url('../../public/images/homepage/track.png');
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        height: 50vh;
    }
    
    .home-buttons {
        margin-top: 2.5rem;
        height: 5.5rem;
    }

    .city-scroller-header {
        font-size: 1.5rem;
        width: 80%; 
    }
    
    .city-scroller-wrapper {
        position: relative;
        width: 100%;
        height: 20vh;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        margin-bottom: -6rem;
    }
    
    .city-scroller-reverse {
        padding: 1rem 0;
        position: absolute;
        top: 3.5vh; 
    }
    
    .city-list-reverse {
        display: inline-block;
        padding-left: 100%;
        animation: scroll 35s linear infinite;
    }
    
    .city-scroller {
        width: 100%;
        padding: 1rem 0;
        top: 8vh;
        z-index: 10; 
    }
    
    .city-list {
        display: inline-block;
        padding-left: 100%;
        animation: scroll-reverse 30s linear infinite;
    }
    
    .home-announcement-cards {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        margin: 2rem 2rem;
        width: 100%;
        cursor: pointer;
    }
    
    .home-card {
        margin-top: 0.5rem;
        border-radius: 20px;
        width: 28vw;
        height: 100%;
        background-color: white;
    }
    
    .home-card-img-top {
        height: 12rem;
        width: 100%;
        border-radius: 20px 20px 0 0;
        margin-bottom: 1rem;
    }
    
    .home-card-body {
        height: 100%;
    }
    
    .home-card-title {
        font-family: 'DMSansBold';
        font-size: 1rem;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        /* Maksimum iki satır */
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    
    .home-card-text {
        font-family: 'DMSansRegular';
        font-size: 0.875rem;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    
    .home-card-content {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
    }
    
    .home-card-date {
        font-family: 'DMSansBold';
        font-size: 1.45vw;
        padding-left: 1rem;
    }
    
    .home-card-date-month {
        color: blue;
        font-family: 'DMSansBold';
        font-size: 0.7rem;
        margin-left: 0.1rem;
    }
    
    .home-card-date-day {
        margin-top: -1rem;
        font-size: 1.75rem;
        font-weight: bold;
    }
    
    .home-card-text-content {
        width: 90%;
        font-size: 0.875rem;
        margin-left: 1rem;
    }
    
}
.archive {
    display: flex;
    gap: 8vh;
    flex-direction: column;
    align-items: center;
    background-image: url('../../public/images/archive/archivebackground.png');
    background-size: cover;
    /* Resmi kapsayacak şekilde ayarlar */
    background-repeat: no-repeat;
    /* Resmin tekrarını engeller */
    width: 100vw;
    min-height: 190vh;
}

.roboRally {
    border: 1px solid black;
    border-radius: 40px;
    background-color: #fff;
    display: inline-block;
    overflow: hidden;
    padding-left: 1rem;
    padding-right: 1rem;
    height: 40px;

}

.roboRally-text {
    margin: 0.5rem;
    font-size: 1vw;
    font-family: "PoppinsLight";
}

.menu-btn-div {
    width: 100vw;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;

}

.menu-btn {
    background-color: #fff;
    color: #000;
    border-radius: 40px;
    padding: 1.2vh 3vw;
    font-size: 1vw;
    font-family: "PoppinsRegular";
    filter: blur(1.5px);
    transition: filter 0.3s ease-in-out;
    margin-bottom: 1vh;

}

.menu-btn:hover,
.menu-btn:active,
.menu-btn:focus {
    filter: blur(0);
    /* Üzerine gelindiğinde veya tıklandığında netleşir */
}

.menu-btn.active {
    filter: blur(0);
    /* Aktif buton net olur */
}

.my-container-archive {
    width: 85%;
    background-color: rgba(255, 255, 255, 0.5);
    /* Saydam arka plan */
    border-radius: 50px;
    display: flex;
    justify-content: center;
    /* İçeriği ortalamak için */
    flex-wrap: wrap;
    padding: 2%;
    box-shadow: inset 0px 2px 5px rgba(100, 100, 100, 0.9);
    /* Beyaz iç kenar gölgeliği */
    margin-bottom: 2%;
}

.parkur-img {
    width: 36vw;
    height: auto;
    object-fit: contain;
    position: relative;
}



.deneme-parkur {

    background-color: rgba(255, 255, 255, 0.4);
    /* Saydam arka plan */
    color: black;
    padding: 25px;
    border-radius: 40px;
    box-shadow: inset 0px 2px 5px rgba(100, 100, 100, 0.9);
    /* Beyaz iç kenar gölgeliği */
    text-align: start;
    font-size: 0.9vw;
    width: 98%;
    margin-left: 1%;


}

.deneme-parkur p:nth-child(1),
.final-parkur-desc p:nth-child(1) {
    font-family: "DMSansBold";
    font-size: 1.25vw
}

.deneme-parkur p:nth-child(2),
.final-parkur-desc p:nth-child(2) {
    font-family: "DMSansRegular";
    font-size: 1vw;
    text-indent: 1rem;
}

.deneme-parkur p:nth-child(3),
.final-parkur-desc p:nth-child(3) {
    font-family: "DMSansRegular";
    font-size: 1vw;
    text-indent: 1rem;
}


.deneme-parkur-title {
    font-family: "DMSansBold";
    font-size: 1.25vw;
}

.deneme-parkur-txt {
    font-family: "DMSansRegular";
    font-size: 1vw;
    line-height: 1.7;

}


.parkur-img-2 {
    width: 42.5vw;
    height: auto;
    object-fit: contain;
    position: relative;
    top: 1%;

}

.photo-container {
    background-color: rgba(255, 255, 255, 0.9);
    /* Saydam arka plan */
    box-shadow: inset 0px 2px 5px rgba(100, 100, 100, 0.7);
    /* Beyaz iç kenar gölgeliği */
    width: 100%;
    padding: 1%;
    margin-top: 4%;
    display: flex;
    justify-content: center;
    /* İçeriği ortalamak için */
    flex-direction: row;
    flex-wrap: wrap;
    position: relative;
    z-index: 1;
    top: -6vh;
}

.photo-container-goruntu {
    background-color: rgba(255, 255, 255, 0.4);
    /* Saydam arka plan */
    box-shadow: inset 0px 2px 5px rgba(100, 100, 100, 0.7);
    /* Beyaz iç kenar gölgeliği */
    width: 100%;
    padding: 5%;
    padding-bottom: 0;
    margin-top: 4%;
    display: flex;
    justify-content: center;
    /* İçeriği ortalamak için */
    flex-direction: row;
    flex-wrap: wrap;
    position: relative;
    z-index: 1;
    top: -6vh;
    overflow: hidden;
}

.final-parkur-desc {

    background-color: rgba(255, 255, 255, 0.4);
    /* Saydam arka plan */
    color: black;
    padding: 25px;
    border-radius: 40px;
    box-shadow: inset 0px 2px 5px rgba(100, 100, 100, 0.9);
    /* Beyaz iç kenar gölgeliği */
    text-align: start;
    font-size: 0.9vw;
    width: 90%;
    line-height: 1.7;


}

.final-images {
    display: flex;
    justify-content: center;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    gap: 3vh;
    margin-top: 3%;
}

.final-images img {
    width: 36vw;
    height: auto;
    object-fit: contain;
    position: relative;
    transition: transform 0.3s ease;
    /* Yumuşak geçiş için */
    cursor: pointer;
    /* Tıklanabilir imleç */
}

.final-images img:hover {
    transform: scale(1.2);
    /* Görsel %120 büyütülür */
    z-index: 1;
    /* Üstte görünmesi için */
}

.yarisma-images img {
    width: 38vw;
    height: auto;
    object-fit: contain;
    position: relative;
    margin: 0.2rem;
}

.yarisma-photo-container1,
.yarisma-photo-container2 {
    display: flex;
    gap: 1vw;
    height: 40vh;
    width: max-content;
    /* Tüm görselleri sığdır */
    position: relative;
}

.yarisma-photo-container1 {
    animation: slideRight 70s linear infinite;
}

.yarisma-photo-container2 {
    animation: slideLeft 70s linear infinite;
    /* Sola doğru kayan animasyon */
}

@keyframes slideRight {
    0% {
        transform: translateX(0);
    }

    100% {
        transform: translateX(-50%);
        /* Tüm görsellerin yarısına kadar kaydır */
    }
}

@keyframes slideLeft {
    0% {
        transform: translateX(0);
    }

    100% {
        transform: translateX(50%);
    }
}

.img-wrapper {
    box-shadow: 0px 2px 5px rgba(100, 100, 100, 0.7);
    padding: 0.5rem;
    width: 32vw;
    height: 35vh;
    background-color: #fff;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    /* Yatayda ortalar */
    align-items: center;
    /* Dikeyde ortalar */
    transition: transform 0.3s ease;
    /* Geçiş animasyonu */


}

.yarisma-photo-container1 img,
.yarisma-photo-container2 img {
    width: 31vw;
    height: 33vh;
    border-radius: 25px;
    object-fit: cover;
    cursor: pointer;
    /* İmleç tıklanabilir yapılıyor */

}

.zoomed {
    transform: scale(1.2);
    /* Görselin büyüme oranı */
    z-index: 1000;
    /* En üstte görünmesi için */
    cursor: pointer;
    /* Tıklanabilir imleç */
}

.paused {
    animation-play-state: paused;
    /* Animasyonu durdur */
}

.derecekazananlar2024 {
    object-fit: cover;
    margin: -2.6%;
    width: 90%;
    height: auto;

}

.yarisanlar2024 {
    object-fit: cover;
    margin: 1%;
    width: 100%;
    height: auto;
}

@media (min-width: 768px) and (max-width: 1024px) {

    .archive {
        gap: 8vh;
        background-size: contain;
        margin-bottom: 2%;
        min-height: 90vh;
    }


    .roboRally {
        padding-left: 1.5rem;
        padding-right: 1.5rem;
        height: 48px;
    }

    .roboRally-text {
        font-size: 3vw;
    }

    .menu-btn {
        width: 26rem;
        font-size: 2.8vw;
        filter: blur(1.2px);

    }

    .my-container-archive {
        flex-direction: column;
        align-items: center;
        width: 98%;
        border-radius: 30px;
        gap: 3vw;
    }

    .parkur-img {
        width: 50vw;

    }

    .deneme-parkur {
        padding: 20px;
        border-radius: 30px;
        /* Beyaz iç kenar gölgeliği */
        text-align: start;
        width: 90%;
        margin-left: 5%;

    }

    .deneme-parkur-title {
        font-size: 3vw !important;
    }

    .deneme-parkur-txt {
        font-size: 2.8vw !important;

    }

    .parkur-img-2 {
        margin-top: 1vh;
        width: 80vw;

    }

    .photo-container {
        height: 30vh;
        max-width: 100vw;
        overflow-x: auto;
        position: relative;
        /* Yatayda kaydırma çubuğu ekler */
        flex-direction: column;
    }

    .photo-container img:nth-child(1) {
        width: 70vw !important;
        height: 25vh !important;
        margin-right: -25%;
    }

    .photo-container img:nth-child(2) {
        width: 85vw !important;
        height: 26vh !important;
        margin-right: -50%;
    }

    .photo-container img:nth-child(3) {
        width: 85vw !important;
        height: 25vh !important;
        margin-right: -25%;
    }

    .photo-container img:nth-child(4) {
        width: 70vw !important;
        height: 25vh !important;
        margin-right: -25%;
    }

    .photo-container::-webkit-scrollbar {
        height: 10px;
        /* Yatay scrollbar için daha dar */
        width: 10px;
        /* Dikey scrollbar için daha dar */
    }

    .photo-container::-webkit-scrollbar-thumb {
        border-radius: 5px;
        /* Mobilde daha küçük tutamaç */
        background: linear-gradient(to right, darkblue, #1bb2ef);
        /* Tutamaç rengi */
    }

    .photo-container::-webkit-scrollbar-thumb:hover {
        background: #127bf3;
    }

    /*  final pakur kısmı*/

    .deneme-parkur p:nth-child(1),
    .final-parkur-desc p:nth-child(1) {
        font-family: "DMSansBold";
        font-size: 3vw
    }

    .deneme-parkur p:nth-child(2),
    .final-parkur-desc p:nth-child(2) {
        font-family: "DMSansRegular";
        font-size: 2.5vw;

    }

    .deneme-parkur p:nth-child(3),
    .final-parkur-desc p:nth-child(3) {
        font-family: "DMSansRegular";
        font-size: 2.5vw;
        line-height: 1.7;
    }

    .final-images,
    .yarisma-images {
        flex-direction: column;
        align-items: center;
    }

    .final-images img,
    .yarisma-images img {
        width: 70vw;
        margin-bottom: 0.2%;
    }


    /*  yarisma görüntüleri kısmı*/


    /* Gerekirse boşlukları daha sıkı hale getirebiliriz */
    .yarisma-photo-container1,
    .yarisma-photo-container2 {
        gap: 2vw;
        margin-bottom: -10vh;
    }

    /* Animasyon hızını biraz yavaşlatabiliriz, çünkü ekran daha küçük */
    .yarisma-photo-container1 {
        animation: slideRight 80s linear infinite;
    }

    .yarisma-photo-container2 {
        animation: slideLeft 80s linear infinite;
    }

    .img-wrapper {
        box-shadow: 0px 2px 5px rgba(100, 100, 100, 0.7);
        padding: 0.5rem;
        width: 58vw;
        height: 25vh;
        background-color: #fff;
        border-radius: 20px;
        display: flex;
        justify-content: center;
        /* Yatayda ortalar */
        align-items: center;
        /* Dikeyde ortalar */
        transition: transform 0.3s ease;
        /* Geçiş animasyonu */


    }

    .yarisma-photo-container1 img,
    .yarisma-photo-container2 img {
        width: 55vw;
        height: 23vh;
        border-radius: 25px;
        object-fit: cover;
        cursor: pointer;
        /* İmleç tıklanabilir yapılıyor */

    }

    .derecekazananlar2024 {
        margin: -2%;
        width: 100%;
    }

    .yarisanlar2024 {

        width: 100%;
    }

}



/* Ekran genişliği 768 pikselden küçükse */
@media (max-width: 768px) {

    .archive {
        gap: 6vh;
        background-size: contain;

    }

    .roboRally-text {
        font-size: 3vw;
    }

    .menu-btn {
        width: 13rem;
        font-size: 3vw;
        filter: blur(1.2px);

    }

    .my-container-archive {
        flex-direction: column;
        align-items: center;
        width: 100%;
        border-radius: 30px;
        gap: 3vw;
    }

    .parkur-img {
        width: 60vw;

    }

    .deneme-parkur-title {
        font-size: 4vw !important;
    }

    .deneme-parkur-txt {
        font-size: 3.5vw !important;

    }

    .parkur-img-2 {
        margin-top: 1vh;
        width: 85vw;

    }

    .photo-container {
        height: 45vh;
        max-width: 100vw;
        overflow-x: auto;
        position: relative;
        /* Yatayda kaydırma çubuğu ekler */
        flex-direction: column;
    }

    .photo-container img:nth-child(1) {
        width: 100vw !important;
        height: 35vh !important;
        margin-right: -15%;
    }

    .photo-container img:nth-child(2) {
        width: 95vw !important;
        height: 35vh !important;
        margin-right: -30%;
    }

    .photo-container img:nth-child(3) {
        width: 95vw !important;
        height: 35vh !important;
        margin-right: -15%;
    }

    .photo-container img:nth-child(4) {
        width: 100vw !important;
        height: 35vh !important;
        margin-right: -15%;
    }

    .photo-container::-webkit-scrollbar {
        height: 15px;
        /* Yatay scrollbar için daha dar */
        width: 15px;
        /* Dikey scrollbar için daha dar */
    }

    .photo-container::-webkit-scrollbar-thumb {
        border-radius: 5px;
        /* Mobilde daha küçük tutamaç */
        background: linear-gradient(to right, darkblue, #1bb2ef);
        /* Tutamaç rengi */
    }

    .photo-container::-webkit-scrollbar-thumb:hover {
        background: #127bf3;
    }

    /*  final pakur kısmı*/

    .deneme-parkur p:nth-child(1),
    .final-parkur-desc p:nth-child(1) {
        font-family: "DMSansBold";
        font-size: 4vw
    }

    .deneme-parkur p:nth-child(2),
    .final-parkur-desc p:nth-child(2) {
        font-family: "DMSansRegular";
        font-size: 3.5vw;

    }

    .deneme-parkur p:nth-child(3),
    .final-parkur-desc p:nth-child(3) {
        font-family: "DMSansRegular";
        font-size: 3.5vw;
        line-height: 1.7;
    }

    .final-images,
    .yarisma-images {
        flex-direction: column;
        align-items: center;
    }

    .final-images img,
    .yarisma-images img {
        width: 85vw;
        margin-bottom: 2%;
    }


    /*  yarisma görüntüleri kısmı*/

    /* Gerekirse boşlukları daha sıkı hale getirebiliriz */
    .yarisma-photo-container1,
    .yarisma-photo-container2 {
        gap: 2vw;
        margin-bottom: -10vh;
    }

    /* Animasyon hızını biraz yavaşlatabiliriz, çünkü ekran daha küçük */
    .yarisma-photo-container1 {
        animation: slideRight 90s linear infinite;
    }

    .yarisma-photo-container2 {
        animation: slideLeft 90s linear infinite;
    }


    .img-wrapper {
        box-shadow: 0px 2px 5px rgba(100, 100, 100, 0.7);
        padding: 0.5rem;
        width: 76vw;
        height: 25vh;
        background-color: #fff;
        border-radius: 10px;
        display: flex;
        justify-content: center;
        /* Yatayda ortalar */
        align-items: center;
        /* Dikeyde ortalar */
        transition: transform 0.3s ease;
        /* Geçiş animasyonu */


    }

    .yarisma-photo-container1 img,
    .yarisma-photo-container2 img {
        width: 74vw;
        height: 24vh;
        border-radius: 10px;
        object-fit: cover;
        cursor: pointer;
        /* İmleç tıklanabilir yapılıyor */

    }

    .derecekazananlar2024 {
        margin: -2%;
        width: 100%;
    }

    .yarisanlar2024 {

        width: 100%;
    }
}
.ask-us-question-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2%;
    gap: 5vh;
    flex-wrap: wrap;
}

.ask-us-title-input,
.ask-us-body-input {
    width: 85%;
    border-radius: 18px;
    box-shadow: inset 0px 2px 2px 1px rgba(150, 146, 146, 0.6);
    resize: none;

}

.ask-us-title-input {
    font-family: 'PoppinsSemiBold';
    font-size: 0.8vw;
    margin-top: 3vh;
    padding: 3px 15px;

 
}

.ask-us-body-input {
    min-height: 45vh;
    font-family: 'PoppinsLight';
    font-size:  0.9vw;
    padding: 15px;

}

.ask-us-question-container p {
    width: 85%;
    /* inputların genişliği ile aynı */
    margin: 0;
    /* default marginleri sıfırlıyoruz */
    padding-left: 15px;
    /* inputların padding'ine uyumlu sol boşluk ekliyoruz */
    text-align: start;
    font-family: 'PoppinsLight';
    font-size:  0.9vw;

}

.ask-usb-btn-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 85%;
    /* inputların genişliği ile aynı */
}

.ask-us-submit-button,
.ask-us-img-submit-button {
    gap: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    border-radius: 10px;
    padding: 1.1vh 2.5vw;
    font-size: 1vw;
    font-family: "PoppinsRegular";
    border: none;

}

.ask-us-submit-button {
    background-color: rgba(247, 167, 19, 0.781);

}

.ask-us-img-submit-button {

    background-color: rgba(28, 189, 230, 0.781);

}

.selected-files-container {
    min-width: 55%;
    max-width: 85%;
    overflow-x: auto;
    /* Yatay kaydırmayı etkinleştir */
    padding: 1rem;
    border: 1px solid #ddd;
    border-radius: 8px;
    background-color: #f9f9f9;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 2rem;
    white-space: nowrap;
    /* Tüm öğeleri yatayda tek bir satırda tut */

}


.image-preview-container {
    position: relative;
    width: 50%;
    height: 120px;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    display: inline-flex;

}

.image-preview {
    width: 100%;
    height: 80px;
    object-fit: cover;
    border-radius: 8px;
}

.image-info {
    margin-top: 0.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 0 0.5rem;
}

.image-name {
    font-size: 0.8rem;
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.remove-icon {
    color: red;
    cursor: pointer;
    font-size: 1rem;
}

.remove-icon:hover {
    color: darkred;
}

@media (min-width: 768px) and (max-width: 1024px) {

    .ask-us-question-container {  
        padding: 1%;
        gap: 3vh;
    }
    
    .ask-us-title-input,
    .ask-us-body-input {
        border-radius: 10px;
    
    }
    
    .ask-us-title-input {
        font-family: 'PoppinsSemiBold';
        font-size: 2vw;
        margin-top: 2vh;
        padding: 3px 15px;

    }
    
    .ask-us-body-input {
        min-height: 38vh;
        font-family: 'PoppinsLight';
        font-size:  2vw;
        padding :8px;
    }
    
    .ask-us-question-container p {
        padding-left: 10px;     
        font-size:  1.8vw;
    
    }
    
    .ask-usb-btn-container {
        flex-direction: row;
    
    }
    
    .ask-us-submit-button,
    .ask-us-img-submit-button {
        border-radius: 5px;
        font-size: 2vw;
    
    }
    
    .ask-us-submit-button {
        background-color: rgba(247, 167, 19, 0.781);
        padding: 1.1vh 5vw;
        margin-bottom: 1rem;
    }
    
    .ask-us-img-submit-button {
    
        background-color: rgba(28, 189, 230, 0.781);
        padding: 1.1vh 3vw;
        margin-bottom: 1rem;

    }
    
    .selected-files-container {
        padding: 0.5rem;
        border: 1px solid #ddd;
        border-radius: 8px;
        gap: 0.5rem;
        white-space: nowrap;
        /* Tüm öğeleri yatayda tek bir satırda tut */
    
    }
    
    
    .image-preview-container {
        position: relative;
        width: 90%;
        height: 100px;
        border-radius: 8px;   
    
    }
    
    .image-preview {
        width: 90%;
        height: 60px;
        border-radius: 5px;
    }
    
    
    .image-name {
        font-size: 0.8rem;
        text-align: left;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

/* Ekran genişliği 768 pikselden küçükse */
@media (max-width: 768px) {

    .ask-us-question-container {  
        padding: 1%;
        gap: 3vh;
    }
    
    .ask-us-title-input,
    .ask-us-body-input {
        border-radius: 10px;
    
    }
    
    .ask-us-title-input {
        font-family: 'PoppinsSemiBold';
        font-size: 3vw;
        margin-top: 2vh;
        padding: 3px 15px;

    }
    
    .ask-us-body-input {
        min-height: 35vh;
        font-family: 'PoppinsLight';
        font-size:  3vw;
        padding :8px;
    }
    
    .ask-us-question-container p {
        padding-left: 10px;     
        font-size:  2.5vw;
    
    }
    
    .ask-usb-btn-container {
        flex-direction: column;
    
    }
    
    .ask-us-submit-button,
    .ask-us-img-submit-button {
        border-radius: 5px;
        font-size: 3vw;
    
    }
    
    .ask-us-submit-button {
        background-color: rgba(247, 167, 19, 0.781);
        padding: 1.1vh 5vw;
        margin-bottom: 1rem;
    }
    
    .ask-us-img-submit-button {
    
        background-color: rgba(28, 189, 230, 0.781);
        padding: 1.1vh 3vw;
        margin-bottom: 1rem;

    }
    
    .selected-files-container {
        padding: 0.5rem;
        border: 1px solid #ddd;
        border-radius: 8px;
        gap: 0.5rem;
        white-space: nowrap;
        /* Tüm öğeleri yatayda tek bir satırda tut */
    
    }
    
    
    .image-preview-container {
        position: relative;
        width: 90%;
        height: 100px;
        border-radius: 8px;   

    }
    
    .image-preview {
        width: 90%;
        height: 60px;
        border-radius: 5px;
    }
    
    
    .image-name {
        font-size: 0.8rem;
        text-align: left;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    
}
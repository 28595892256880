body, html {
    margin: 0;
    padding: 0;
    overflow-x: hidden; /* Ensure no overflow is allowed */
}

.footer {
    background-color: #333;
    color: #fff;
    padding: 2rem;
}

.footer-container {
    max-width: 1500px;
    margin: 0 auto;
}

.footer-row {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    text-align: center;
}

.footer-logo,
.footer-menu,
.footer-contact {
    flex: 1;
    margin-bottom: 1rem;
}

.footer-logo img {
    max-height: 8rem;
    width: auto;
}

.footer-menu ul {
    list-style-type: none;
    padding: 0;
}

.footer-menu li {
    margin-bottom: 1rem;
    cursor: pointer;
    font-size: 1rem;
}

.footer-contact p {
    margin-bottom: 1rem;
}

.social-icons a {
    margin-right: 1rem;
}

.social-icons img {
    height: 30px;
    width: 30px;
}

.footer-bottom {
    text-align: center;
    margin-top: 2rem;
    font-size: 0.9rem;
}

.version-text {
    margin-top: 0.5rem;
}

.poppins-extra-light {
    font-family: 'PoppinsExtraLight';
}

.poppins-bold {
    font-family: 'PoppinsBold';
}

.poppins-semi-bold {
    font-family: 'PoppinsSemiBold';
}

/* Responsive Styles for Tablets */
@media (max-width: 1024px) {
    .footer-row {
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-around;
    }

    .footer-logo img {
        max-height: 6rem;
    }

    .footer-menu li {
        font-size: 1.1rem;
    }

    .social-icons img {
        height: 35px;
        width: 35px;
    }
}

/* Responsive Styles for Mobile Devices */
@media (max-width: 768px) {
    .footer-row {
        flex-direction: column;
        align-items: center;
    }

    .footer-logo img {
        max-height: 5rem;
    }

    .footer-menu li {
        font-size: 1rem;
    }

    .social-icons img {
        height: 40px;
        width: 40px;
    }
}

/* Desktop Screens */
@media (min-width: 1025px) {
    
    .footer-bottom {
        display: flex;
        justify-content: space-between;
        align-items: center;
        text-align: left;
    }

    .footer-menu {
        text-align: left;
        margin-left: 2.5rem;
    }

    .footer-bottom p {
        flex: 1;
        text-align: center;
    }

    .version-text {
        margin-left: auto;
        text-align: right;
    }
}